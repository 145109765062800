<template>
  <div>
    <div
      class="modal"
      id="accrueOpenPosModal"
      tabindex="-1"
      role="dialog"
    >
      <div
        class="modal-dialog modal-xl modal-po-lg modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Select PO's to Accrue
            </h4>
            <button type="button" class="close" aria-label="Close" @click.prevent="closeModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="vhc-cost-custom-row">
              <div class="vhc-cost-custom-column">
                <div class="vhc-cost-fm-group">
                  <label for="unitId">Stock#</label>
                  <input type="text" class="modal-input" v-model="unitId" disabled />
                </div>
                <div class="vhc-cost-fm-group">
                  <label for="calculatedTotal">Total Accrue Amount</label>
                  <money
                    v-bind="moneyMask"
                    type="text"
                    class="modal-input"
                    v-model="totalAccrueAmount"
                    disabled
                  />
                </div>
                <div id="btn-chkbox-gp">
                  <button
                    class="vhc-cost-modal-btn"
                    v-on:click="createAccrueAddOn()"
                  >
                    Create Accrue Add-On
                  </button>
                </div>
              </div>
            </div>
            <vehicle-pos v-bind:assetWiseExpensesArr="assetWiseExpensesArr" v-bind:allStaticMessages="allStaticMessages" disableStockedPOsChk="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../../../http-common";
import EventBus from "../../../event-bus";
import { commonMixins } from "../../../../mixins/commonMixins";
import CommonLoader from "@/components/partials/CommonLoader.vue";
import VehiclePos from "./CostSheet/VehiclePOSModal.vue";
import { Money } from "v-money";

export default {
  name: "AccrueOpenPosModal",
  components: {
    "vehicle-pos": VehiclePos,
    Money,
  },
  mixins: [commonMixins],
  props: ["assetId", "unitId", "vin", "dealInventoryId"],
  data() {
    return {
      assetWiseExpensesArr: [],
      accrueOpenPosModalInstance: null,
      totalPoAmount: "",
      moneyMask: {
        thousands: ",",
        prefix: "$ ",
        precision: 2,
      },
      totalAccrueAmount: this.totalPoAmount,
      isAddonLoader: false,
    };
  },
  beforeMount() {
    self = this;
  },
  mounted() {
    self.initializeModals();
  },
  methods: {
    initializeModals() {
      self.accrueOpenPosModalInstance = new bootstrap.Modal(
          document.getElementById("accrueOpenPosModal"),
            {
              backdrop: "static",
              keyboard: false,
            }
      );
    },
    openModal(){
      self.accrueOpenPosModalInstance.show();
      self.getData();
    },
    closeModal(){
      self.accrueOpenPosModalInstance.hide();
    },
    addSubCalulateTotal(status, value) {
      let amount = value.replace("$", "").replace(",", "");
      amount = parseFloat(amount);
      const total = parseFloat(this.totalAccrueAmount);
      if (status) {
        this.totalAccrueAmount = parseFloat(total + amount).toFixed(2);
      } else {
        this.totalAccrueAmount = parseFloat(total - amount).toFixed(2);
      }
      return true;
    },
    getData: async function () {
      await this.loadAssetExpenses;
    },
    createAccrueAddOn() {

      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };

        // Collect selected POs
        const selectedPOs = this.assetWiseExpensesArr.filter(item => item.isSelected);

        selectedPOs.forEach(async (po) => {
          let data = {
          deal_inventory_id: self.dealInventoryId,
          excede_item_id: null,
          price: 0,
          description: po.summary,
          cost: parseFloat(po.actual_cost_wo_fmt),
          tax: 'EXEMPT (0.00000)',
          is_not_charged: true,
          is_no_cost: false,
          is_add_to_total_price: false,
          is_show_on_documents: false,
          is_fet_exempt: false,
          is_do_not_add_to_gp: false,
          id: null,
          is_edit: false,
          excede_item: null,
          temp_index: null,
          is_accrue_add_on: true,
          excede_po: po.excede_po
        };

        self.isAddonLoader = true;
        HTTP.post("store/add-on-deal", data, config)
            .then((response) => {
              self.isAddonLoader = false;
              $("#commonLoaderAddonItem").find(".loading-container.lodingTable").hide();
              EventBus.$emit("add-on-data", response.data.content, 'store', false);
              self.closeModal();
            })
            .catch(function (err) {
              self.isAddonLoader = false;
              $("#commonLoaderAddonItem").find(".loading-container.lodingTable").hide();
            });
      });

    }
  },
  watch: {
    totalPoAmount(newVal) {
      this.totalAccrueAmount = this.totalPoAmount + newVal;
    },
  },
  computed: {
    loadAssetExpenses: function () {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      let fields = {
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: "id",
        sortType: "desc",
        asset_id: self.assetId,
      };

      return new Promise(function (resolve, reject) {
        HTTP.post("/assetwiseexpenses/list", fields, config)
          .then(function (data) {
            if (data.status == 200) {
              self.assetWiseExpensesArr = data.data.content.data;
              self.totalAccrueAmount = data.data.totalPoAmount;
              if (
                self.totalAccrueAmount == null ||
                typeof self.totalAccrueAmount === "undefined"
              ) {
                self.totalAccrueAmount = 0;
              }
              resolve(data);
            }
          })
          .catch(function (error) {
            self.listExecutionState = false;
            self.catchHandler(error, function () {});
            reject(error);
          });
      });
    },
  },
}

EventBus.$on("add-subtract-total", (status, value) => {
  if (typeof self != "undefined") {
    self.addSubCalulateTotal(status, value);
  }
});

</script>
<style :scoped>

.modal-title {
  color: #506b71;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.vhc-cost-custom-row {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 14px;
}
.vhc-cost-custom-column {
  display: grid;
  grid-template-columns: 3fr 3fr 7fr;
  grid-column-gap: 80px;
}

.vhc-cost-modal-btn {
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;

  height: 30px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 1px;
  background: linear-gradient(174deg, #076eb0 0%, rgba(7, 110, 176, 0.57) 100%);
  color: #fff;
  text-align: center;
  font-family: "SF UI Display", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
}

.vhc-cost-fm-group {
  display: flex;
  flex-direction: column;
}

.vhc-cost-fm-group-checkbox {
  margin-top: 9px;
}

.vhc-cost-fm-group-checkbox > div {
  padding: 2px;
}

.input-text {
  color: #242731;
  text-align: right;
  font-family: SF UI Display, Arial, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

</style>