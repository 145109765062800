<template>
	<div class="custom-container">
	<!-- Start Top -->
	<div id="top">
		<!-- First Row -->
		<div class="custom-row">
		<!-- Left Column -->
		<div class="custom-column">
			<h4 class="top-head-title">{{labelMessages.vws_title}}</h4>
			<span class="sub-title">{{ dealNo }}</span>
		</div>
		<!-- Right Column -->
		<div class="custom-column" id="button-top">
			<button class="close-button" @click="closeWorkSheet()">{{labelMessages.close}}</button>
			<button class="save-close-button" v-if="canUpdateDealDetails" @click="saveCloseWorkSheet()">
			<span>{{dealInventoryMapperId !== null || isVehicleEdit ? labelMessages.label_update : labelMessages.save}}</span>
			</button>
		</div>
		</div>
		<!-- Second Row -->
		<!-- Note here the use of HTML COMMENT between buttons to remove the white space -->
		<div class="custom-row">
		<div class="custom-column">
			<!--#### NOT A FUNCTIONAL SO NOT REQUIRED FOR NOW  ####
			<button class="menu-btn trs-btn-disabled">
			<i class="btn-icon"></i>
			{{labelMessages.trns_save_as_template}}
			</button>
			<button class="menu-btn trs-btn-disabled">
			<i class="btn-icon"></i>
			{{labelMessages.vws_print_page}}
			</button>
			<button class="menu-btn trs-btn-disabled">
			<i class="btn-icon"></i>
			{{labelMessages.trns_move_to_deal}}
			</button>
			-->
			<button 
			:class="((dealInventoryMapperId != null || vehicleDataId !== null) 
			&& permissionNameArray.includes('can_delete_deal_inventory_mapper')) 
			? 'menu-btn' 
			: 'menu-btn trs-btn-disabled'" 
			@click="(dealInventoryMapperId != null || vehicleDataId !== null) ? deleteVehicleWorksheet() : ''">
			<i class="btn-icon"></i>
			{{labelMessages.vws_delete}}
			</button>
		</div>
		</div>
	</div>
	<!-- End Top -->
	<hr />
	<!-- Start Main Content -->
	<div class="main">
		<div class="custom-row">
		<div class="vehicle-info-section">
			<div class="custom-accordian">
			<div class="custom-card">
				<div class="custom-card-header" @click="expandCollapse('expandCollapse1',1)">
				<div class="custom-column">{{labelMessages.vws_vehicle_information}}</div>
				<div class="custom-column">
					<img src="/static/images/transaction_sheet/Vector.svg" alt="Vector" :class="expandCollapseImg1" />
				</div>
				</div>
				<div id="vhc-info">
				<div class="custom-card-body" :class="expandCollapse1">
					<div class="custom-row">
					<div class="custom-column">
						<div class="fm-group">
							<label for="nu">{{labelMessages.vws_new_used}}</label>
							<v-select
							v-model="typeId"
							name="typeId"
							id="nu"
							label="text"
							:clearable="false"
							:searchable="false"
							:filterable="false"
							:options="typeArr"
							:placeholder="labelMessages.type_place_holder"
							@input="newUsedCheck()"
							class="vhc-select"
							:disabled="isVehicleEdit || isNewUsedDisabled"
							/>
						</div>
						<div class="fm-group">
						<label for="vin">{{labelMessages.vws_vin}} <span class="vhc-required" :title="requiredFieldInfo">*</span></label>
						<div class="custom-input-search">
							<input type="text" id="vin" v-model="vin" @input="vinError=''" :disabled="isStockVin" />
							<div class="search-icon" @click="searchVin"></div>
						</div>
						<search-vin-modal
							v-bind:isClickSearchVinModal="isClickSearchVinModal"
							v-bind:vin="vin"
							v-bind:usedVinList="usedVinList"
						></search-vin-modal>
						<span class="vhc-required-alert" v-if="vinError !== ''">{{ vinError }}</span>
						</div>
						<div class="fm-group">
							<label for="unit-id">{{labelMessages.vws_unit_id}}</label>
							<div class="custom-input-search">
							<input type="text" v-model="unitId" @input="unitError=''" />
							<div class="search-icon" @click="searchUnit"></div>
							</div>
							<search-unit-modal
							v-bind:isClickSearchUnitModal="isClickSearchUnitModal"
							v-bind:unit="unitId"
							v-bind:usedUnitList="usedUnitList"
							></search-unit-modal>
							<span class="vhc-required-alert" v-if="unitError !== ''">{{ unitError }}</span>
						</div>
					</div>
					<div class="custom-column">
							<div class="fm-group">
							<label for="stock-order">{{labelMessages.vws_stock_order}}  <span class="vhc-required" :title="requiredFieldInfo">*</span></label>
							<v-select
								v-model="stockOrder"
								name="stockOrder"
								id="stock-order"
								label="text"
								:clearable="false"
								:searchable="false"
								:filterable="false"
								:options="stockOrderArr"
								:placeholder="labelMessages.stock_order_place_holder"
								class="vhc-select"
								@input="handleVin"
							/>
							<span class="vhc-required-alert" v-if="stockOrderError !== ''">{{ stockOrderError }}</span>
							</div>
							<div class="fm-group">
								<label for="manufacturer">{{labelMessages.vws_manufacturer}} <span class="vhc-required" :title="requiredFieldInfo">*</span></label>
								<v-select
									v-model="manufacturerId"
									name="manufacturerId"
									label="text"
									:filterable="true"
									:options="manufacturerArr"
									:clearable="true"
									:placeholder="
									labelMessages.manufacturer_place_holder
									"
									@input="getHoldbackCalculationData('manufacturerError')"
									class="custom-ci-auto-search vhc-select"
								/>
								<span class="vhc-required-alert" v-if="manufacturerError !== ''">{{ manufacturerError }}</span>
							</div>
							<div class="fm-group">
								<label for="model">{{labelMessages.vws_model}} <span class="vhc-required" :title="requiredFieldInfo">*</span></label>
								<v-select
									v-model="modelOEMId"
									name="modelOEMId"
									label="text"
									:filterable="true"
									:options="modelOEMArr"
									:clearable="true"
									:placeholder="labelMessages.model_oem_place_holder"
									@input="getHoldbackCalculationData('modelError')"
									class="custom-ci-auto-search vhc-select"
								/>
								<span class="vhc-required-alert" v-if="modelError !== ''">{{ modelError }}</span>
							</div>
							<div class="fm-group">
								<label for="color">{{labelMessages.vws_color}}</label>
								<input type="text" id="color" v-model="color" />
							</div>
						</div>
					<div class="custom-column">
						<div class="custom-column">
							<div class="fm-group">
							<label for="year">{{labelMessages.vws_year}}</label>
							<input type="text" v-model="year" />
							</div>
							<div class="fm-group">
								<label for="stock-type">{{labelMessages.vws_stock_type}}</label>
								<v-select
									v-model="stockTypeId"
									name="stockTypeId"
									id="stock-type"
									label="text"
									:clearable="false"
									:searchable="false"
									:filterable="false"
									:options="stockTypeArr"
									:placeholder="labelMessages.stock_type_place_holder"
									@input="getHoldbackCalculationData"
									class="vhc-select"
								/>
							</div>
							<div class="fm-group">
								<label for="body-type">{{labelMessages.vws_body_type}}</label>
								<input type="text" id="body-type" v-model="bodyType" />
							</div>
							<div class="fm-group">
								<label for="gvw">{{labelMessages.vws_gvw}}</label>
								<input
									type="text"
									v-model="gvwGCWR"
									@keyup="
									(event) => {
										incrementDecrementNumber(event, 'gvwGCWR');
									}
									"
								/>
							</div>
						</div>
					</div>
					<div class="custom-column">
						<div class="fm-group">
						<label for="nu">{{labelMessages.vws_odometer}}</label>
						<input type="text" v-model="odometer" />
						</div>
						<div class="fm-group">
						<label for="scale-wt">{{labelMessages.vws_hours}}</label>
						<input
							type="text"
							id="scale-wt"
							v-model="hours"
						/>
						</div>
						<div class="fm-group">
							<label for="fuel-type">{{labelMessages.vws_fuel_type}}</label>
							<v-select
							v-model="fuelTypeId"
							name="fuelTypeId"
							id="fuel-type"
							label="text"
							:clearable="false"
							:searchable="false"
							:filterable="false"
							:options="fuelTypeArr"
							:placeholder="
								labelMessages.fuel_type_place_holder
							"
							class="vhc-select"
							/>
						</div>
						<div class="fm-group">
							<label for="tires">{{labelMessages.vws_of_tires}}</label>
							<input
							type="text"
							id="tires"
							v-model="ofTires"
							@keyup="
								(event) => {
								incrementDecrementNumber(event, 'ofTires');
								}
							"
							@focusout="sumFees()"
							/>
						</div>
					</div>
					<div class="custom-column">
						<div class="fm-group">
						<label for="delvery-date">{{labelMessages.vws_proposed_delivery_date}}</label>
						<div id="date-calendar-fm-group">
							<date-picker
							v-model="proposedDeliveryDate"
							:config="options"
							class="form-control"
							autocomplete="off"
							name="proposedDeliveryDate"
							id="delivery-date"
							ref="dateInput"
							>
							</date-picker>
							<i @click="openCalendar" style="cursor: pointer"
							><img
								src="/static/images/transaction_sheet/Group4719.svg"
								alt=""
							/></i>
						</div>
						</div>
						<div class="fm-group">
						<label for="duty">{{labelMessages.vws_duty}}</label>
						<v-select
							v-model="duty"
							name="duty"
							id="duty"
							label="text"
							:clearable="false"
							:searchable="false"
							:filterable="false"
							:options="dutyArr"
							:placeholder="labelMessages.duty_place_holder"
							class="vhc-select"
						/>
						</div>
						<div class="fm-group">
							<label for="quantity">{{labelMessages.vws_quantity}}</label>
							<input type="text" value="1" v-model="quantity" />
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		</div>
		<div class="custom-row">
		<div class="vehicle-info-section">
			<div class="custom-accordian">
			<div class="custom-card">
				<div class="custom-card-header" @click="expandCollapse('expandCollapse2',2)">
				<div class="custom-column">{{labelMessages.vws_fees}}</div>
				<div class="custom-column">
					<img
					src="/static/images/transaction_sheet/Vector.svg"
					alt="Vector"
					:class="expandCollapseImg2"
					/>
				</div>
				</div>
				<div id="fees-info">
				<div class="custom-card-body" :class="expandCollapse2">
					<div class="custom-row">
						<div class="fm-group">
							<label for="doc-pp-fees">{{labelMessages.vws_doc_preparation_fees}}</label>
							<money
							v-bind="moneyMask"
							type="text"
							class="input-text"
							v-model="docPreparation"
							@keyup.native="
								(event) => {
								incrementDecrementNumber(event, 'docPreparation');
								}
							"
							@blur.native="sumFees()"
							/>
						</div>
						<div class="fm-group">
							<label for="license-title">{{labelMessages.vws_license_title}}</label>
							<money
							v-bind="moneyMask"
							type="text"
							class="input-text"
							v-model="licenseTitle"
							:disabled="!customFee"
							@keyup.native="
								(event) => {
								incrementDecrementNumber(event, 'licenseTitle');
								}
							"
							@blur.native="sumFees()"
							/>
							<div class="fm-group-checkbox">
								<input
								type="checkbox"
								class="custom-checkbox"
								v-model="noLicense"
								@change="checkUncheck($event, 'noLicense')"
								/>
								<label for="no-license">{{labelMessages.vws_no_license}}</label>
								<input
								type="checkbox"
								class="custom-checkbox"
								v-model="customFee"
								@change="checkUncheck($event, 'customFee')"
								/>
								<label for="no-license">{{labelMessages.vws_custom_fee}}</label>
							</div>
						</div>
						<div class="fm-group">
							<label for="tire-fee">{{labelMessages.vws_tire_fee}}</label>
							<money
								v-bind="moneyMask"
								type="text"
								class="input-text"
								v-model="tireFee"
								@blur.native="sumFees()"
							/>
							<div id="vws-per-tire-fee">
								<money
								v-bind="moneyMask"
								type="text"
								class="input-text"
								v-model="tireTax"
								@keyup.native="
									(event) => {
									incrementDecrementNumber(event, 'tireTax');
									}
								"
								@blur.native="sumFees()"
								/>
								<label>{{labelMessages.vws_per_tire}}</label>
							</div>
						</div>
						<div class="fm-group">
						<label for="fleet-reserve-cost"
							>{{labelMessages.vws_finance_reserve}}</label
						>
						<money
							v-bind="moneyMask"
							type="text"
							class="input-text vws-inp-excl"
							v-model="financeReserve"
							@keyup.native="
							(event) => {
								incrementDecrementNumber(
								event,
								'financeReserve'
								);
							}
							"
						/>
						</div>
						<div class="fm-group">
						<label for="factory-invoice">{{labelMessages.vws_factory_inv_date}}</label>
						<date-picker
							v-model="factoryInvDate"
							:config="options"
							class="form-control vws-inp-excl"
							autocomplete="off"
							name="factoryInvDate"
							id="factory-inv-date"
							>
							</date-picker>
						</div>
					</div>
					<div class="custom-row">
						<div class="fm-group">
						<label for="license-title">{{labelMessages.vws_admin_fee}}</label>
						<money
							v-bind="moneyMask"
							type="text"
							class="input-text"
							v-model="adminFee"
							@keyup.native="
							(event) => {
								incrementDecrementNumber(event, 'adminFee');
							}
							"
							@blur.native="sumFees()"
							:disabled="excludeAdminFee"
						/>
						<div class="fm-group-checkbox">
							<input
								type="checkbox"
								class="custom-checkbox"
								v-model="onInvoice"
								@change="checkUncheck($event, 'onInvoice')"
							/>
							<label for="no-license">{{labelMessages.vws_on_invoice}}</label>
							<input
								type="checkbox"
								class="custom-checkbox"
								v-model="offInvoice"
								@change="checkUncheck($event, 'offInvoice')"
							/>
								<label for="no-license">{{labelMessages.vws_off_invoice}}</label>
							</div>
							<div class="vws-single-checkbox">
								<input
									type="checkbox"
									class="custom-checkbox"
									v-model="excludeAdminFee"
									@change="checkUncheck($event, 'excludeAdminFee')"
								/>
								<label for="no-license">{{labelMessages.vws_exclude_admin_fee}}</label>
							</div>
						</div>
						<div class="fm-group">
						<label for="registration">{{labelMessages.vws_registration}}</label>
						<money
							v-bind="moneyMask"
							type="text"
							class="input-text"
							v-model="registration"
							@keyup.native="
							(event) => {
								incrementDecrementNumber(event, 'registration');
							}
							"
						@blur.native="sumFees()"
						/>
							<div class="fm-group">
								<label for="dmv-electronic "
									>{{labelMessages.vws_dmv_electronic_filling}}</label
								>
								<money
									v-bind="moneyMask"
									type="text"
									class="input-text"
									v-model="dmvElectronicFillingFee"
									@keyup.native="
									(event) => {
										incrementDecrementNumber(
										event,
										'dmvElectronicFillingFee'
										);
									}
									"
									@blur.native="sumFees()"
								/>
							</div>
						</div>
						<div class="fm-group">
						<label for="smog-cert"
							>{{labelMessages.vws_smog_certification_or_exemption}}
							</label
						>
						<money
							v-bind="moneyMask"
							type="text"
							class="input-text"
							v-model="smogCertificationOrExemptionFeePaidToState"
							@keyup.native="
								(event) => {
								incrementDecrementNumber(
									event,
									'smogCertificationOrExemptionFeePaidToState'
								);
								}
							"
							@blur.native="sumFees()"
							/>
							<div class="fm-group">
								<label for="smog-fee">{{labelMessages.vws_smog_fee_paid_to_seller}}</label>
								<money
									v-bind="moneyMask"
									type="text"
									class="input-text"
									v-model="smogFeePaidToSeller"
									@keyup.native="
									(event) => {
										incrementDecrementNumber(
										event,
										'smogFeePaidToSeller'
										);
									}
									"
									@blur.native="sumFees()"
								/>
							</div>
						</div>
						<div class="fm-group">
						<label for="adot-permit">{{labelMessages.vws_adot_permit}}</label>
						<input
							type="text"
							class="input-text vws-inp-excl"
							v-model="adotPermit"
						/>
						<div class="fm-group">
							<label for="body-cost">{{labelMessages.vws_body_cost}}</label>
							<money
							v-bind="moneyMask"
							type="text"
							class="input-text vws-inp-excl"
							v-model="bodyCost"
							@keyup.native="
								(event) => {
								incrementDecrementNumber(event, 'bodyCost');
								}
							"
							/>
						</div>
						</div>
						<div class="fm-group">
						<label for="hold-date">{{labelMessages.vws_hold_date}}</label>
						<date-picker
							v-model="holdDate"
							:config="options"
							class="form-control vws-inp-excl"
							autocomplete="off"
							name="holdDate"
							id="hold-date"
							>
							</date-picker>
							<div class="fm-group">
								<label for="factory-price">{{labelMessages.vws_factory_cost}}</label>
								<money
									v-bind="moneyMask"
									type="text"
									class="input-text vws-inp-excl"
									v-model="factoryPrice"
									@blur.native="vhcCostCalculation()"
									@keyup.native="
									(event) => {
										incrementDecrementNumber(event, 'factoryPrice');
									}
									"
								/>
							</div>
						</div>
					</div>
					<div class="custom-row">
						<div class="fm-group">
						<label for="sales-tax">{{labelMessages.vws_imf_sales_tax}}</label>
						<money
							v-bind="moneyMask"
							type="text"
							class="input-text"
							v-model="imfSalesTax"
							@keyup.native="
							(event) => {
								incrementDecrementNumber(event, 'imfSalesTax');
							}
							"
							@blur.native="sumFees()"
						/>
						</div>
						<div class="fm-group">
						<label for="fleet-cma-ctrl"
							>{{labelMessages.vws_fleet_cma_ctrl}}</label
						>
						<input
							type="text"
							class="input-text vws-inp-excl"
							v-model="fleetCmaCtrl"
						/>
						</div>
						<div class="fm-group">
						<label for="cst_spl_bdy">{{labelMessages.vws_customer_supplied_body}}</label>
						<money
							v-bind="moneyMask"
							type="text"
							class="input-text vws-inp-excl"
							v-model="customerSuppliedBody"
							@keyup.native="
							(event) => {
								incrementDecrementNumber(
								event,
								'customerSuppliedBody'
								);
							}
							"
						/>
						</div>
						<div class="fm-group">
						<label for="flooring">{{labelMessages.vws_body_flooring}}</label>
						<div class="inner-grid">
							<money
							v-bind="moneyMask"
							type="text"
							class="input-text vws-inp-excl"
							v-model="bodyFlooring"
							disabled
							/>
							<a href="javascript:void(0);" @click="calFlooring('bodyCost')" title="Calculate Body Flooring Based On Body Cost">
							<img
								src="/static/images/transaction_sheet/calculator1.svg"
								alt="Calculatior Icon"
							/>
							</a>
						</div>
						</div>
						<div class="fm-group">
						<label for="flooring">{{labelMessages.vws_flooring}}</label>
							<div class="inner-grid">
							<money
								v-bind="moneyMask"
								type="text"
								class="input-text vws-inp-excl"
								v-model="chassisFlooring"
								disabled
							/>
							<a href="javascript:void(0);" @click="calFlooring()" title="Calculate Chassis Flooring Based On Factory Cost">
								<img
								src="/static/images/transaction_sheet/calculator1.svg"
								alt="Calculatior Icon"
								/>
							</a>
							</div>
							<cal-flooring
							v-bind:isClickCalFlooring="isClickCalFlooring"
							v-bind:flooringTypeValue="flooringTypeValue"
							v-bind:isVisibleDDC="isVisibleDDC"
							v-bind:deliveryDelayCredit="deliveryDelayCredit"
							v-bind:typeId="typeId"
							v-bind:flooringStartDate="flooringStartDate"
							v-bind:flooringEndDate="flooringEndDate"
							v-bind:flooringRate="flooringRate"
							v-bind:storedFlooring="flooring"
							></cal-flooring>
						</div>
					</div>
					<div class="custom-row">
						<div class="fm-group">
						<label for="deal-adjustment">{{labelMessages.vws_deal_adjustment_nc}}</label>
						<money
							v-bind="moneyMask"
							type="text"
							class="input-text"
							v-model="dealAdjustment"
							@blur.native="sumFees()"
							@keyup.native="
							(event) => {
								incrementDecrementNumber(event, 'dealAdjustment');
							}
							"
						/>
						</div>
						<div class="fm-group">
						<label for="sales-tax">{{labelMessages.vws_tn_business_tax}}</label>
						<money
							v-bind="moneyMask"
							type="text"
							class="input-text"
							v-model="tnBusinessTax"
							@keyup.native="
							(event) => {
								incrementDecrementNumber(event, 'tnBusinessTax');
							}
							"
							@blur.native="sumFees()"
						/>
						</div>
						<div class="fm-group">
						<label for="fleet-reserve-cost"
							>{{labelMessages.vws_fleet_cma_cost}}</label
						>
						<money
							v-bind="moneyMask"
							type="text"
							class="input-text"
							v-model="fleetCmaCost"
							@keyup.native="
							(event) => {
								incrementDecrementNumber(
								event,
								'fleetCmaCost'
								);
							}
							"
							@blur.native="sumFees()"
						/>
						</div>
						<div class="fm-group">
						<div class="label-with-btn">
							<label for="total-fees">{{labelMessages.vws_total_fees}}</label>
							<img src="/static/images/transaction_sheet/detail_icon.svg" alt="Total Fees" id="total-fees-icon">
						</div>
						<money
							v-bind="moneyMask"
							type="text"
							class="input-text vws-inp-total"
							v-model="feesTotal"
							disabled
						/>
						<b-popover target="total-fees-icon" triggers="hover" placement="bottom">
							{{ viewTotalFeesFormula }}
						</b-popover>
						</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		</div>
		<div class="custom-row" v-if="typeId.text.toLowerCase() != 'used'">
		<div class="custom-accordian">
			<div class="custom-card">
			<div class="custom-card-header" @click="expandCollapse('expandCollapse7',7)">
				<div class="custom-column">{{labelMessages.vws_trade_detail}}</div>
				<div class="custom-column">
				<img
					src="/static/images/transaction_sheet/Vector.svg"
					alt="Vector"
					:class="expandCollapseImg7"
				/>
				</div>
			</div>
			<div id="trade-detail">
				<div class="custom-card-body" :class="expandCollapse7">
				<div class="custom-row">
					<div class="custom-column">
					<button v-if="!tradeDetailsData && canUpdateDealDetails" class="vhc-btn"  @click="tradeDetailModal('Save')">Add Trade</button>
					</div>
				</div>
				<div class="custom-row">
					<table class="custom-table">
					<caption>{{labelMessages.vws_trade_detail}}</caption>
					<thead>
						<th>{{labelMessages.trade_year}}</th>
						<th>{{labelMessages.trade_make}}</th>
						<th>{{labelMessages.trade_model}}</th>
						<th>{{labelMessages.trade_gross_trade_in}}</th>
						<th>{{labelMessages.trade_pay_off}}</th>
						<th>{{labelMessages.trade_acv}}</th>
						<th>{{labelMessages.vws_vin}}</th>
						<th>{{labelMessages.trade_stock}}</th>
						<th>{{labelMessages.trade_appraisal}}</th>
						<th></th>
					</thead>
					<tbody>
						<tr v-if="tradeDetailsData">
						<td>{{  tradeDetailsData.year  }}</td>
						<td>{{  tradeDetailsData.make.text  }}</td>
						<td>{{  tradeDetailsData.model.text  }}</td>
						<td>{{  tradeDetailsData.trade_in_value  }}</td>
						<td>{{  tradeDetailsData.payoff  }}</td>
						<td>{{  tradeDetailsData.trade_acv  }}</td>
						<td>{{  tradeDetailsData.vin  }}</td>
						<td>{{  tradeDetailsData.stock_id  }}</td>
						<td></td>
						<td>
							<a
							v-on:click="tradeDetailModal('Edit')"
							title="Edit Trade Detail"
							href="javascript:void(0)"
							class="mar-right-0"
							v-if="canUpdateDealDetails"
							>
							<i aria-hidden="true" class="fa fa-edit"></i>
							</a>
						</td>
						</tr>
						<tr v-else>
						<td colspan="3" id="table-colspan">
							{{labelMessages.no_records_to_display_label}}
						</td>
						</tr>
					</tbody>
					</table>
				</div>
				</div>
			</div>
			</div>
		</div>
		</div>
		<div class="custom-row">
		<div class="custom-accordian">
			<div class="custom-card">
				<div class="custom-card-header" @click="expandCollapse('expandCollapse3',3)">
				<div class="custom-column">{{labelMessages.vws_addons_pos}}</div>
				<div class="custom-column">
					<img
					src="/static/images/transaction_sheet/Vector.svg"
					alt="Vector"
					:class="expandCollapseImg3"
					/>
				</div>
				</div>
				<div id="trade-detail">
				<div class="custom-card-body" :class="expandCollapse3">
					<div class="custom-row">
					<div class="custom-column">
						<button class="vhc-btn" v-if="canUpdateDealDetails" @click="addOnModal()">
						{{labelMessages.vws_addon_list}}
						</button>
						<button class="vhc-btn" v-if="canUpdateDealDetails" @click="purchaseOrderModal()">
						{{labelMessages.vws_purchase_order}}
						</button>
						<button class="vhc-btn" v-if="canUpdateDealDetails" @click="accrueOpenPosModal()">
						{{labelMessages.vws_accrue_open_po}}
						</button>
					</div>
					</div>
					<div class="custom-row">
						<item-list 
							:addOnItems="addOnItems" 
							:purchaseOrderList="purchaseOrderList" 
							:labelMessages="labelMessages" 
							:permissionNameArray="permissionNameArray" 
							:dealStatusSlug="dealStatusSlug" 
							:isGetItemApiCall="isGetItemApiCall" 
						/>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		<div class="custom-row">
		<div class="vehicle-cost-calculation">
			<div class="custom-accordian">
			<div class="custom-card">
				<div class="custom-card-header" @click="expandCollapse('expandCollapse4',4)">
				<div class="custom-column">{{labelMessages.vehicle_cost_calculation}}</div>
				<div class="custom-column">
					<img
					src="/static/images/transaction_sheet/Vector.svg"
					alt="Vector"
					:class="expandCollapseImg4"
					/>
				</div>
				</div>
				<div id="vhc-cost-cal-info">
				<div class="custom-card-body" :class="expandCollapse4">
					<div class="custom-row">
						<div class="fm-group"></div>
						<div class="fm-group"></div>
						<div class="fm-group">
							<label for="vhc-final-selling-price">{{labelMessages.vws_final_selling_price}}</label>
							<money
								v-bind="moneyMask"
								type="text"
								class="input-text"
								v-model="finalSellingPrice"
								@keyup.native="
									(event) => {
									incrementDecrementNumber(
										event,
										'finalSellingPrice'
									);
									}
								"
								@blur.native="finalSellingPriceCalc()"
							/>
						</div>
					</div>
					<div class="custom-row">
					<div class="fm-group">
						<div class="label-with-btn">
							<label for="cost-from-excede">{{labelMessages.vws_cost_from_excede}}</label>
							<label for="cost-sheet">
								<a href="javascript:void(0)" @click="vehicleCostSheet()" title="Click To Open Cost Sheet">
									{{ labelMessages.vws_cost_sheet }}
								</a>
							</label>
						</div>
						<money
							v-bind="moneyMask"
							type="text"
							class="input-text"
							v-model="costFromExcede"
							@keyup.native="
								(event) => {
									incrementDecrementNumber(
										event,
										'costFromExcede'
									);
								}
							"
							disabled
						/>
					</div>
					<div class="fm-group">
						<div class="label-with-btn">
						<input type="checkbox" @change="includeManualOverrideCalculation($event)" v-model="isManualOverride" />
						<label for="nu">{{labelMessages.vws_manual_override}}</label>
						</div>
						<money
						v-bind="moneyMask"
						type="text"
						class="input-text"
						v-model="manualOverride"
						@keyup.native="
							(event) => {
							incrementDecrementNumber(
								event,
								'manualOverride'
							);
							}
						"
						@blur.native="isManualOverride ? vhcCostCalculation('reCalculate') : null"
						/>
					</div>
					<div class="">
						<label for="vhc-inventory-cost">{{labelMessages.vws_inventory_cost}}</label>
						<money
							v-bind="moneyMask"
							type="text"
							class="vhc-input-text vws-inp-cal"
							v-model="inventoryCost"
							@keyup.native="
							(event) => {
								incrementDecrementNumber(
								event,
								'inventoryCost'
								);
							}
							"
							disabled
						/>
					</div>
					</div>
					<div class="custom-row">
					<div class="fm-group">
						<label for="vhc-holdback-fixed">{{labelMessages.vws_holdback_fixed}}</label>
						<money
						v-bind="moneyMask"
						type="text"
						class="input-text"
						v-model="holdbackFixed"
						@keyup.native="
							(event) => {
							incrementDecrementNumber(
								event,
								'holdbackFixed'
							);
							}
						"
						@blur.native="vhcCostCalculation('holdbackFixed')"
						:disabled="!customHoldback"
						/>
					</div>
					<div class="fm-group">
						<label for="vhc-holdback-percent">{{labelMessages.vws_holdback_percent}} {{ holdbackPercentBasedOn }}</label>
						<money
						v-bind="rateMask"
						type="text"
						class="input-text"
						v-model="holdbackPercent"
						@keyup.native="
							(event) => {
							incrementDecrementNumber(
								event,
								'holdbackPercent'
							);
							}
						"
						@blur.native="vhcCostCalculation('holdbackPercent')"
						:disabled="!customHoldback"
						/>
					</div>
					<div class="">
						<label for="vhc-holdback">{{labelMessages.vws_holdback}}</label>
						<money
						v-bind="moneyMask"
						type="text"
						class="vhc-input-text vws-inp-cal"
						v-model="holdback"
						@keyup.native="
							(event) => {
							incrementDecrementNumber(
								event,
								'holdback'
							);
							}
						"
						disabled
						/>
					</div>
					</div>
					<div class="custom-row">
					<div>
						<div class="fm-group-checkbox">
							<input
							type="checkbox"
							class="custom-checkbox"
							v-model="customHoldback"
							@change="checkUncheck($event, 'customHoldback')"
							/>
							<label for="custom-holdback">{{labelMessages.vws_custom_holdback}}</label>
						</div>
					</div>
					<div></div>
					<div>
						<label for="vhc-total-cost-of-fet">{{labelMessages.vws_total_cost_of_fet_eligible}}</label>
						<money
						v-bind="moneyMask"
						type="text"
						class="vhc-input-text vws-inp-cal"
						v-model="totalCostOfFetEligibleAddOrPO"
						disabled
						/>
					</div>
					</div>
					<div class="custom-row">
					<div></div>
					<div></div>
					<div>
						<label for="vhc-cost-subtotal">{{labelMessages.vws_cost_subtotal}}</label>
						<money
						v-bind="moneyMask"
						type="text"
						class="vhc-input-text vws-inp-total"
						v-model="costSubTotal"
						@keyup.native="
							(event) => {
							incrementDecrementNumber(
								event,
								'costSubTotal'
							);
							}
						"
						disabled
						/>
					</div>
					</div>
					<div class="custom-row">
						<div class="fm-group" id="vhc-truck-margin-field">
						<div>
							<label for="vhc-truck-margin">{{labelMessages.vws_truck_margin_percent}}</label>
							<money
								v-bind="rateMask"
								type="text"
								class="input-text"
								v-model="truckMarginPercent"
								:disabled="typeId.text.toLowerCase() == 'used'"
								@keyup.native="
								(event) => {
									incrementDecrementNumber(
									event,
									'truckMarginPercent'
									);
								}
								"
								@blur.native="vhcCostCalculation('holdbackFixed')"
							/>
						</div>
						<div>
							<label for="vhc-truck-margin">{{labelMessages.vws_truck_margin_amount}} &nbsp;&nbsp;</label>
							<label for="cost-sheet" v-if="typeId.text.toLowerCase() == 'new'">
								<a href="javascript:void(0)" @click="calculateMargin()" title="Calculate Margin from Final Selling Price">
									{{ labelMessages.vws_truck_margin_calculate_link }}
								</a>
							</label>
							<money
								v-bind="moneyMask"
								type="text"
								class="input-text"
								v-model="truckMarginAmount"
								:disabled="typeId.text.toLowerCase() == 'used'"
								@keyup.native="
									(event) => {
										incrementDecrementNumber(
										event,
										'truckMarginAmount'
										);
									}
								"
								@blur.native="vhcCostCalculation('holdbackFixed', 'truckMarginAmount')"
							/>
						</div>
					</div>
					<div class="fm-group">
						<div>
						<label for="vhc-other-fet-app-markups">{{labelMessages.vws_other_fet_app_markups}}</label>
						<money
							v-bind="moneyMask"
							type="text"
							class="input-text"
							v-model="otherFetAppMarkups"
							:disabled="typeId.text.toLowerCase() == 'used'"
							@keyup.native="
							(event) => {
								incrementDecrementNumber(
								event,
								'otherFetAppMarkups'
								);
							}
							"
							@blur.native="vhcCostCalculation('holdbackFixed', 'truckMarginAmount')"
						/>
						</div>
					</div>
					<div class="">
						<label for="vhc-truck-margin-total">{{labelMessages.vws_truck_margin}}</label>
						<money
							v-bind="moneyMask"
							type="text"
							class="vhc-input-text vws-inp-cal"
							v-model="truckMargin"
							disabled
						/>
					</div>
					</div>
					<div class="custom-row">
					<div></div>
					<div></div>
					<div>
						<label for="vhc-overallowance">{{labelMessages.vws_overallowance}}</label>
						<money
						v-bind="moneyMask"
						type="text"
						class="vhc-input-text vws-inp-cal"
						v-model="overallowance"
						disabled
						/>
					</div>
					</div>
					<div class="custom-row">
					<div></div>
					<div></div>
					<div>
						<label for="vhc-truck-margin-total">
						<small>(Cost Subtotal+Truck Margin+Overallowance) </small>
						<span>{{labelMessages.vws_total_for_fet_cal}}</span>
						</label>
						<money
						v-bind="moneyMask"
						type="text"
						class="vhc-input-text"
						v-model="baseSalesPrice"
						:disabled="typeId.text.toLowerCase() == 'used'"
						@blur.native="reverseTruckMarginFromFetCalculation()"
						/>
					</div>
					</div>
					<div class="custom-row">
					<div class="fm-group">
						<label for="vhc-tire-credits">{{labelMessages.vws_tire_credits}}</label>
						<money
						v-bind="moneyMask"
						type="text"
						class="input-text"
						v-model="tireCredits"
						:disabled="typeId.text.toLowerCase() == 'used'"
						@keyup.native="
							(event) => {
							incrementDecrementNumber(
								event,
								'tireCredits'
							);
							}
						"
						@blur.native="vhcCostCalculation()"
						/>
					</div>
					<div class="fm-group">
						<div>
						<label for="vhc-fet-exempt-items">{{labelMessages.vws_fet_exempt_items}}</label>
						<money
							v-bind="moneyMask"
							type="text"
							class="input-text"
							v-model="fetExemptItems"
							disabled
						/>
						</div>
					</div>
					<div class="">
						<div class="item-with-checkbox">
							<div class="fm-group-checkbox">
								<input
									type="checkbox"
									class="custom-checkbox"
									v-model="isFet"
									:disabled="typeId.text.toLowerCase() == 'used'"
									@change="checkUncheck($event, 'isFet')"
								/>
								<label for="fet-exempt">{{labelMessages.vws_fet_exempt}}</label>
							</div>
							<label for="vhc-fet">{{labelMessages.vws_fet}}</label>
						</div>
						<money
							v-bind="moneyMask"
							type="text"
							class="vhc-input-text vws-inp-cal"
							v-model="fet"
							disabled
						/>
					</div>
					</div>
					<div class="custom-row">
					<div>
						<div class="fm-group-checkbox">
							<input
							type="checkbox"
							class="custom-checkbox"
							v-model="isToCustomer"
							@change="checkUncheck($event, 'isToCustomer')"
							/>
							<label for="to-customer">{{labelMessages.vws_to_customer}}</label>
							<input
							type="checkbox"
							class="custom-checkbox ml-4"
							v-model="isToDealer"
							@change="checkUncheck($event, 'isToDealer')"
							/>
							<label for="to-dealer">{{labelMessages.vws_to_dealer}}</label>
						</div>
					</div>
					<div></div>
					<div>
						<label for="vhc-truck-selling-price">{{labelMessages.vws_truck_selling_price_incl_fet}}</label>
						<money
						v-bind="moneyMask"
						type="text"
						class="vhc-input-text vws-inp-total"
						v-model="truckSellingPriceInclFet"
						disabled
						/>
					</div>
					</div>

					<!-- State Tax Amount -->
					<div class="custom-row">
					<div></div>
					<div class="fm-group">
						<label for="vhc-state-tax-rate">{{labelMessages.vws_state_tax_rate_label}}</label>
						<money
						v-bind="rateMask"
						type="text"
						class="input-text"
						v-model="stateTaxRate"
						disabled
						@keyup.native="
							(event) => {
							incrementDecrementNumber(
								event,
								'stateTaxRate'
							);
							}
						"
						@blur.native="vhcCostCalculation()"
						/>
					</div>
					<div>
						<label for="vhc-state-tax-amount">{{labelMessages.vws_state_tax_amount_label}}</label>
						<money
						v-bind="moneyMask"
						type="text"
						class="vhc-input-text vws-inp-cal"
						v-model="stateTaxAmount"
						disabled
						/>
					</div>
					</div>

					<!-- Warranty Tax Amount -->
					<div class="custom-row">
					<div></div>
					<div class="fm-group">
						<label for="vhc-custom-tax-rate">{{labelMessages.vws_custom_tax_rate_label}}</label>
						<money
						v-bind="rateMask"
						type="text"
						class="input-text"
						v-model="customTaxRate"
						@keyup.native="
							(event) => {
							incrementDecrementNumber(
								event,
								'customTaxRate'
							);
							}
						"
						@blur.native="vhcCostCalculation()"
						/>
					</div>
					<div>
						<label for="vhc-custom-tax-amount">{{labelMessages.vws_warranty_tax_label}}</label>
						<money
						v-bind="moneyMask"
						type="text"
						class="vhc-input-text vws-inp-cal"
						v-model="customTaxAmount"
						disabled
						/>
					</div>
					</div>
					
					<div class="custom-row">
					<div></div>
					<div></div>
					<div>
						<label for="vhc-total-cost-of-fees">{{labelMessages.vws_total_cost_of_fees}}</label>
						<money
						v-bind="moneyMask"
						type="text"
						class="vhc-input-text vws-inp-cal"
						v-model="totalCostOfFees"
						disabled
						/>
					</div>
					</div>
					<div class="custom-row">
					<div></div>
					<div></div>
					<div>
						<label for="vhc-total-cost-of-non-fet">{{labelMessages.vws_total_cost_of_non_fet_add_po}}</label>
						<money
						v-bind="moneyMask"
						type="text"
						class="vhc-input-text vws-inp-cal"
						v-model="totalCostOfNonFetAddOrPO"
						disabled
						/>
					</div>
					</div>
					<div class="custom-row">
					<div></div>
					<div></div>
					<div>
						<label for="vhc-total-selling-price">{{labelMessages.vws_total_selling_price_incl_fet}}</label>
						<money
						v-bind="moneyMask"
						type="text"
						class="vhc-input-text vws-inp-total"
						v-model="totalSellingPriceInclFet"
						@keyup.native="
							(event) => {
							incrementDecrementNumber(
								event,
								'totalSellingPriceInclFet'
							);
							}
						"
						disabled
						/>
					</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		</div>

		<div class="custom-row">
			<div class="commission-estimate">
				<div class="custom-accordian">
					<div class="custom-card">
						<div class="custom-card-header" @click="expandCollapse('expandCollapse8',8)">
							<div class="custom-column">{{labelMessages.commission_estimate}}</div>
							<div class="custom-column">
						        <img
									src="/static/images/transaction_sheet/Vector.svg"
									alt="Vector"
									:class="expandCollapseImg8"
								/>
						    </div>
						</div>
						<div id="com-cal-info">
							<div class="custom-card-body" :class="expandCollapse8">
					            <div class="custom-row">
									<div></div>
									<div></div>
						            <div class="fm-group">
							            <label for="gross-profit">{{labelMessages.vws_gross_profit}}</label>
							                <money 
												v-bind="moneyMask"
												type="text"
												class="input-text"
								                v-model="grossProfit"
												@keyup.native="
													(event) => {
													incrementDecrementNumber(
														event,
														'grossProfit'
													);
													}
												"
												disabled
											/>
									</div>
									<div>
										<label for="com-less-holdback">{{labelMessages.vws_less_holdback}}</label>
										<money 
											v-bind="moneyMask"
											type="text"
											class="vhc-input-text vws-inp-cal"
											v-model="lessHoldback"
											@keyup.native="
												(event) => {
												incrementDecrementNumber(
													event,
													'lessHoldback'
												);
												}
											"
											disabled
										/>
									</div>
								</div>
								<div class="custom-row">
									<div></div>
									<div></div>
									<div></div>
									<div>
							            <label for="com-over-allowance">{{labelMessages.vws_over_allowance}}</label>
							                <money 
												v-bind="moneyMask"
												type="text"
												class="vhc-input-text vws-inp-cal"
												v-model="comOverAllowance"
												@keyup.native="
													(event) => {
													incrementDecrementNumber(
														event,
														'comOverAllowance'
													);
													}
												"
												disabled
											/>
									</div>
								</div>	
					            <div class="custom-row">
									<div class="fm-group">
										<br>
										<label id="commissionableGP">{{labelMessages.vws_commissionable_gross_profit}}</label>
									</div>
									<div class="fm-group">
							            <label for="amtexcl">{{labelMessages.vws_amt_excl}}</label>
							                <money 
												v-bind="moneyMask"
												type="text"
												class="input-text"
												v-model="amtExcl"
												@keyup.native="
													(event) => {
													incrementDecrementNumber(
														event,
														'amtExcl'
													);
													}
												"
												disabled
											/>
									</div>
									<div class="fm-group">
										<label for="grossProfitPercentageToSales">{{ labelMessages.vws_gross_profit_percentage_to_sales }}</label>
											<money
												v-bind="rateMask2"
												type="text"
												class="input-text"
												v-model="grossProfitPercentageToSales"
												style="margin-top: auto"
												@keyup.native="
													(event) => {
													incrementDecrementNumber(
														event,
														'grossProfitPercentageToSales'
													);
													}
												"
												disabled
											/>
									</div>
									<div>
							            <label for="com-gross-profit-after-holdback">{{labelMessages.vws_gross_profit_after_holdback_MISC}}</label>
										<money
												v-bind="moneyMask"
												type="text"
												class="vhc-input-text"
												style="margin-top: auto"
												v-model="grossProfitAfterHoldback"
												@keyup.native="
													(event) => {
													incrementDecrementNumber(
														event,
														'grossProfitAfterHoldback'
													);
													}
												"
												disabled
											/>
									</div>	
							    </div>
								<div class="custom-row">
									<div></div>
									<div class="fm-group">
										<br>
										<label id="commissionLbl">{{labelMessages.vws_commission}}</label>
									</div>
									<div id="com-percent-field">
										<div class="fm-group">
											<label for="commissionRate">{{labelMessages.vws_commission_rate}}</label>
												<money 
													v-bind="rateMask2"
													type="text"
													class="input-text"
													v-model="commissionRate"
													@keyup.native="
														(event) => {
														incrementDecrementNumber(
															event,
															'commissionRate'
														);
														}
													"
													@blur.native="calculateCommission()"
												/>
										</div>
										<div class="fm-group">
											<label for="commissionPercentage"></label>
												<money
													v-bind="rateMask2"
													type="text"
													class="input-text"
													style="margin-top: auto"
													v-model="commissionPercentage"
													@keyup.native="
														(event) => {
														incrementDecrementNumber(
															event,
															'commissionPercentage'
														);
														}
													"
													disabled
												/>
										</div>
									</div>

									<div>
							            <label for="com-commission-amount">{{labelMessages.vws_commission_amount}}</label>
											<money
												v-bind="moneyMask"
												type="text"
												class="vhc-input-text"
												style="margin-top: auto"
												v-model="commissionAmount"
												@keyup.native="
													(event) => {
													incrementDecrementNumber(
														event,
														'commissionAmount'
													);
													}
												"
											/>
									</div>	
							    </div>
								<div class="custom-row">
									<div></div>
									<div></div>
									<div></div>
									<div>
							            <label for="com-additional-commission">{{labelMessages.vws_additional_commission}}</label>
							                <money 
												v-bind="moneyMask"
												type="text"
												class="vhc-input-text"
												v-model="additionalCommission"
												@keyup.native="
													(event) => {
													incrementDecrementNumber(
														event,
														'additionalCommission'
													);
													}
												"
												@blur.native="calculateCommission()"
											/>
									</div>
								</div>
								<div class="custom-row">
									<div></div>
									<div></div>
									<div></div>
									<div>
							            <label for="com-fandi-bonus-for-salesperson">{{labelMessages.vws_fandi_bonus_for_salesperson}}</label>
							                <money 
												v-bind="moneyMask"
												type="text"
												class="vhc-input-text"
												v-model="fandiBonusForSalesperson"
												@keyup.native="
													(event) => {
													incrementDecrementNumber(
														event,
														'fandiBonusForSalesperson'
													);
													}
												"
												disabled
											/>
									</div>
								</div>
								<div class="custom-row">
									<div></div>
									<div></div>
									<div>
							            <label for="com-flat-commission-only">{{labelMessages.vws_flat_commission_only}}</label>
							                <money 
												v-bind="moneyMask"
												type="text"
												class="vhc-input-text"
												v-model="flatCommissionOnly"
												@keyup.native="
													(event) => {
													incrementDecrementNumber(
														event,
														'flatCommissionOnly'
													);
													}
												"
												@blur.native="calculateCommission()"
											/>
									</div>
									<div>
							            <label for="com-total-commission">{{labelMessages.vws_total_commission}}</label>
							                <money 
												v-bind="moneyMask"
												type="text"
												class="vhc-input-text vws-inp-cal"
												v-model="totalCommission"
												@keyup.native="
													(event) => {
													incrementDecrementNumber(
														event,
														'totalCommission'
													);
													}
												"
												disabled
											/>
									</div>
								</div>
								<div class="custom-row">
									<br>
								</div>
								<div class="custom-row">
									<div></div>
									<div class="fm-group-checkbox">
										<input
										type="checkbox"
										id="split-commission"
										v-model="isSplitCommission"
										class="custom-checkbox"
										@change="checkUncheck($event, 'isSplitCommission')"
										/>
										<label for="split-commission">{{labelMessages.vws_split_commission}}</label>
								    </div>
									<div class="fm-group-checkbox">
										<input
										type="checkbox"
										id="no-commission"
										v-model="isNoCommission"
										class="custom-checkbox ml-4"
										@change="checkUncheck($event, 'isNoCommission')"
										/>
										<label for="no-commission">{{labelMessages.vws_no_commission}}</label>
								    </div>
									<div></div>
								</div>
								<div class="split-commission-row" v-if="isSplitCommission">
									<!-- Commission Owner 1 -->
									<div class="custom-row">
										<div class="fm-group">
											<v-select
											    v-model="commOwner1"
												name="commOwner1"
												id="commOwner1"
												label="text"
												:options="salesmanArr"
												class="vhc-select"
											/>
											<span class="help is-danger">{{commOwner1Error}}</span>
										</div>
										<div class="fm-group">
												<money
													v-model="commOwner1Percent"
													v-bind="rateMask2"
													type="text"
													class="input-text"
													style="margin-top: auto"
													@blur.native="calculateCommission()"
												/>
										</div>
										<div class="fm-group">
												<money
													v-model="commOwner1Amount"
													v-bind="moneyMask"
													type="text"
													class="input-text"
													@blur.native="resetCommOwnerPercent('commOwner1Amount')"
												/>
										</div>
									</div>
									<!-- Commission Owner 2 -->
									<div class="custom-row">
										<div class="fm-group">
											<v-select
											    v-model="commOwner2"
												name="commOwner2"
												id="commOwner2"
												label="text"
												:options="salesmanArr"
												class="vhc-select"
											/>
											<span class="help is-danger">{{commOwner2Error}}</span>
										</div>
										<div class="fm-group">
												<money
													v-model="commOwner2Percent"
													v-bind="rateMask2"
													type="text"
													class="input-text"
													style="margin-top: auto"
													@blur.native="calculateCommission()"
												/>
										</div>
										<div class="fm-group">
												<money
													v-model="commOwner2Amount"
													v-bind="moneyMask"
													type="text"
													class="input-text"
													@blur.native="resetCommOwnerPercent('commOwner2Amount')"
												/>
										</div>
								    </div>
									<!-- Commission Owner 3 -->
									<div class="custom-row">
										<div class="fm-group">
											<v-select
											    v-model="commOwner3"
												name="commOwner3"
												id="commOwner3"
												label="text"
												:options="salesmanArr"
												class="vhc-select"
											/>
											<span class="help is-danger">{{commOwner3Error}}</span>
										</div>
										<div class="fm-group">
												<money
													v-model="commOwner3Percent"
													v-bind="rateMask2"
													type="text"
													class="input-text"
													style="margin-top: auto"
													@blur.native="calculateCommission()"
												/>
										</div>
										<div class="fm-group">
												<money
													v-model="commOwner3Amount"
													v-bind="moneyMask"
													type="text"
													class="input-text"
													@blur.native="resetCommOwnerPercent('commOwner3Amount')"
												/>
										</div>
									</div>
									<!-- Commission Owner 4 -->
									<div class="custom-row">
										<div class="fm-group">
											<v-select
											    v-model="commOwner4"
												name="commOwner4"
												id="commOwner4"
												label="text"
												:options="salesmanArr"
												class="vhc-select"
											/>
											<span class="help is-danger">{{commOwner4Error}}</span>
										</div>
										<div class="fm-group">
												<money
													v-model="commOwner4Percent"
													v-bind="rateMask2"
													type="text"
													class="input-text"
													style="margin-top: auto"
													@blur.native="calculateCommission()"
												/>
										</div>
										<div class="fm-group">
												<money
													v-model="commOwner4Amount"
													v-bind="moneyMask"
													type="text"
													class="input-text"
													@blur.native="resetCommOwnerPercent('commOwner4Amount')"
												/>
										</div>
									</div>
									<!-- Commission Owner 5 -->
									<div class="custom-row">
										<div class="fm-group">
											<v-select
											    v-model="commOwner5"
												name="commOwner5"
												id="commOwner5"
												label="text"
												:options="salesmanArr"
												class="vhc-select"
											/>
											<span class="help is-danger">{{commOwner5Error}}</span>
										</div>
										<div class="fm-group">
												<money
													v-model="commOwner5Percent"
													v-bind="rateMask2"
													type="text"
													class="input-text"
													style="margin-top: auto"
													@blur.native="calculateCommission()"
												/>
										</div>
										<div class="fm-group">
												<money
													v-model="commOwner5Amount"
													v-bind="moneyMask"
													type="text"
													class="input-text"
													@blur.native="resetCommOwnerPercent('commOwner5Amount')"
												/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="page-loader-wrapper loading-container" v-if="showTrnsLoader">
		<div class="loading">
		<img src="/static/images/loading.gif" alt="loader" />
		</div>
	</div>
	<add-on-deal
		v-bind:addOnEditData="addOnEditData"
		v-bind:editAssetId="addOnAssetId"
		v-bind:addOnTempIndex="addOnTempIndex"
		v-bind:customerCountyId="county"
		v-bind:isClickAddOnList="isClickAddOnList"
		v-bind:canUpdateDealDetails="canUpdateDealDetails"
	></add-on-deal>
	<vehicle-purchase-order
		v-bind:isClickPurchaseOrder="isClickPurchaseOrder"
		v-bind:unitId="unitId"
		v-bind:vin="vin"
		v-bind:defaultLocation="locationId"
		v-bind:assetId="vehicleId"
		v-bind:editAssetId="poEditAssetId"
		v-bind:poEditData="poEditData"
		v-bind:canUpdateDealDetails="canUpdateDealDetails"
	></vehicle-purchase-order>
	<accrue-open-pos-modal
		v-bind:assetId="vehicleId"
		v-bind:unitId="unitId"
		v-bind:vin="vin"
		v-bind:dealInventoryId="editAssetId"
		ref="accrueOpenPosModalRef"
	></accrue-open-pos-modal>
	<vehicle-cost-modal
		v-bind:assetId="vehicleId"
		v-bind:vin="vin"
		v-bind:isClickCostSheet="isClickCostSheet"
		v-bind:unitId="unitId"
	></vehicle-cost-modal>
	<trade-detail 
		v-bind:currentVehicleWorksheetId="currentVehicleWorksheetId"
		v-bind:isClickAddTrade="isClickAddTrade"
		v-bind:isEditTradeDetail="isEditTradeDetail"
		v-bind:tradeDetailsData="tradeDetailsData"
	></trade-detail>
	<vehicle-detail v-bind:isClickVehicleDetail="isClickVehicleDetail" v-bind:vinNo="vin"></vehicle-detail>
	<mail-modal :isClickMail="isClickMail" :id="dealId" :mailDescription="mailDescription" :locationId="locationId" :dealStatusSlug="dealStatusSlug" :trnsMail="false" :dealNo="dealNo" :excedePurId="mailExcedePurId"></mail-modal>
	</div>
	<!-- End Main Content -->
</template>

<script>
let self;
import Vue from "vue";
import { commonMixins } from "../../../mixins/commonMixins";
import EventBus from "../../event-bus";
import { HTTP } from "../../../http-common";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import NoAccessPage from "../../NoAccessPage/NoAccessPage";
import moment from "moment";
import { Money } from "v-money";
import searchVinModal from "./Modal/searchVinModal.vue";
import vSelect from "vue-select";
import AddOnDealModal from "./Modal/AddOnDealModal.vue";
import VehiclePurchaseOrder from "./Modal/PurchaseOrder/VehiclePurchaseOrderModal.vue";
import AccrueOpenPosModal from "./Modal/AccrueOpenPosModal.vue";
import VehicleCostModal from "./Modal/VehicleCostModal.vue";
import FlooringCalModal from "./Modal/FlooringCalModal.vue";
import searchUnitModal from "./Modal/searchUnitModal.vue";
import Datepicker from 'vuejs-datepicker'; 
import TradeDetail from "./Modal/TradeDetailModal.vue";
import VehicleDetailModal from "./Modal/VehicleDetailModal.vue";
import MailModal from "./Modal/Mail/MailModal.vue";
import ItemList from "./Tables/ItemList.vue";

Vue.component("v-select", vSelect);

export default {
	name: "VehicleWorkSheet",
	components: {
	datePicker,
	"no-access": NoAccessPage,
	Money,
	"search-vin-modal": searchVinModal,
	"add-on-deal": AddOnDealModal,
	"vehicle-purchase-order": VehiclePurchaseOrder,
	"accrue-open-pos-modal": AccrueOpenPosModal,
	"vehicle-cost-modal": VehicleCostModal,
	"cal-flooring": FlooringCalModal,
	"search-unit-modal": searchUnitModal,
	"trade-detail": TradeDetail,
	"vehicle-detail": VehicleDetailModal,
	"mail-modal": MailModal,
	"item-list": ItemList,
	Datepicker
	},
	mixins: [commonMixins],
	props: {
	editAssetId: 0,
	dealId: 0,
	vehicleData: "",
	isVehicleEdit: false,
	county: "", //customerCountyId Required only for Addons

	cusStateCode: "",
	salesTaxCounty: "",
	locationId: "",
	cusStateTaxRate: "",
	cusTaxRate: "",
	cusApplySalesTax: true,
	warrantyTax: "",
	dealNo: "",
	permissionNameArray: [],
	vehicleType: "",
	dealStatusSlug: String,
	customer: "",
	salesperson: "",
	usedVinList: "",
	usedUnitList: "",
	canUpdateDealDetails: { type: Boolean, default: true },
	},
	data() {
		return {
			vin: "",
			isClickSearchVinModal: false,
			isClickSearchUnitModal: false,

			// Old
			moneyMask: {
			// decimal: ',',
			thousands: ",",
			prefix: "$ ",
			// suffix: ' #',
			precision: 2,
			},
			rateMask: {
				decimal: ".",
				suffix: " %",
				precision: 4,
			},
			rateMask2: {
				decimal: ".",
				suffix: " %",
				precision: 2,
			},
			labelMessages: "",

			// Latest
			initialCal: false,
			isVinChange: false,
			factoryInvDate: "",
			factoryPrice: 0,
			typeId: {
				text: 'New'
			},
			typeArr: [
			{
				text: 'New'
			},
			{
				text: 'Used'
			}
			],
			docPreparation: 0,
			financeReserve: 0,

			// From form
			
			
			feesTotal: 0.0,
			isClickCalFlooring: false,

			// AddOn
			addOnEditData: {},
			isClickAddOnList: false,
			addOnTempIndex: null,
			// PO
			poEditData: {},
			isClickPurchaseOrder: false,
			unitId: "",
			vehicleId: null,
			// Cost Sheet
			isClickCostSheet: false,
			// VIN
			manufacturerId: "",
			manufacturerArr: [],
			year: "",
			yearError: "",
			modelOEMId: "",
			modelOEMArr: [],
			color: "",
			colorError: "",
			stockTypeId: "",
			stockTypeArr: [
			{
				text: "RV Coach",
				value: "rv coach"
			},
			{
				text: "RV Hauler",
				value: "rv hauler"
			},
			{
				text: "RV SportDeck",
				value: "rv sportdeck"
			},
			{
				text: "RV Trailer",
				value: "rv trailer"
			},
			{
				text: "Tractor",
				value: "tractor"
			},
			{
				text: "Trailer",
				value: "trailer"
			},
			{
				text: "Truck",
				value: "truck"
			},
			{
				text: "Truck/Tractor",
				value: "truck/tractor"
			},
			{
				text: "Used HD",
				value: "used hd"
			}
			],
			stockTypeError: "",
			bodyType: "",
			bodyTypeError: "",
			odometer: "",
			odometerError: "",
			hours: "",
			vehicleDescription: "",
			vehicleDescriptionError: "",
			// Unused Fields
			duty: "",
			dutyArr: [
			{
				text: 'HVY DUTY',
				value: 'hvy duty',
			},
			{
				text: 'LIGHT DUTY',
				value: 'light duty',
			},
			{
				text: 'MED DUTY',
				value: 'med duty',
			},
			{
				text: 'MED DUTY JAPANESE',
				value: 'med duty japanese',
			},
			{
				text: 'WHOLESALE',
				value: 'wholesale',
			},
			{
				text: 'BODY BUILDER',
				value: 'body builder',
			}
			],
			stockOrder: "",
			stockOrderArr: [
			{
				text: 'Stock',
				value: 'stock'
			},
			{
				text: 'Order',
				value: 'order'
			},
			{
				text: 'Misc Invoice',
				value: 'misc_invoice'
			}
			],

			// Save
			dealInventoryMapperId: null,
			vehicleDataId: null,
			proposedDeliveryDate: "",
			holdDate: "",
			saveNotNull: false,
			options: {
			format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
			useCurrent: false,
			allowInputToggle: true,
			widgetPositioning: {
				horizontal: "auto",
				vertical: "bottom",
			},
			// disabledTimeIntervals: false,
			},

			/*##### ACCORDIAN ANIMATION #####*/
			collapseContain: false,
			expandCollapse1: "vhc-expand",
			expandCollapse2: "vhc-expand",
			expandCollapse3: "vhc-expand",
			expandCollapse4: "vhc-expand",
			expandCollapse5: "vhc-expand",
			expandCollapse6: "vhc-expand",
			expandCollapse7: "vhc-expand",
			expandCollapse8: "vhc-expand",
			expandCollapseImg1: '',
			expandCollapseImg2: '',
			expandCollapseImg3: '',
			expandCollapseImg4: '',
			expandCollapseImg5: '',
			expandCollapseImg6: '',
			expandCollapseImg7: 'rotate-accordian-expand',
			expandCollapseImg8: '',

			// Added
			quantity: 1,
			poEditAssetId: null,
			poTempData: [],
			addOnAssetId: null,
			poIndex: null,
			manufacturerError: '',
			modelError: '',
			vinError: '',
			unitError: '',
			deliveryDelayCredit: 0,
			tempDeliveryDelayCredit: 0,
			initFlooring: 0,
			isVisibleDDC: false,
			flooringStartDate: moment(new Date()).format(process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT),
			flooringEndDate: moment(new Date()).format(process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT),
			flooringRate: 6.50,
			chassisFlooringStartDate: moment(new Date()).format(process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT),
			chassisFlooringEndDate: moment(new Date()).format(process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT),
			chassisFlooringRate: 6.50,
			bodyFlooringStartDate: moment(new Date()).format(process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT),
			bodyFlooringEndDate: moment(new Date()).format(process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT),
			bodyFlooringRate: 6.50,

			// Trade Detail
			isClickAddTrade: false,
			isEditTradeDetail: false,
			currentVehicleWorksheetId: null,
			tradeDetailsData: null,
			isTradeSave: false,
			showTrnsLoader: false,

			mailDescription: {},
			isClickMail: false,
			mailExcedePurId: "",
			addOnPoList: [],

			addOnItems: [],
			purchaseOrderList: [],

			// Required
			gvwGCWR: 0,
			fuelTypeId: "",
			fuelTypeArr: [],
			ofTires: 0,
			customFee: false,
			licenseTitle: "",
			noLicense: false,
			tireFee: 0,
			tireTax: 0,
			registration: 0,
			dmvElectronicFillingFee: 0,
			smogCertificationOrExemptionFeePaidToState: 0,
			smogFeePaidToSeller: 0,
			imfSalesTax: 0,
			customerSuppliedBody: 0,
			flooring: 0,
			bodyFlooring: 0,
			chassisFlooring: 0,
			flooringType: "factoryPrice",
			flooringTypeValue: 0,
			isClickVehicleDetail: false,
			isGetItemApiCall: false,
			

			// new
			bodyCost: 0,
			dealAdjustment: 0,
			tnBusinessTax: 0,
			fleetCmaCost: 0,
			adminFee: 0,
			adotPermit: "",
			fleetCmaCtrl: "",
			onInvoice: true,
			offInvoice: false,
			excludeAdminFee: false,
			isManualOverride: false,
			isStockVin: false,
			stockOrderError: "",

			// Holdback Calculation DB Data
			dbHoldbackPercent: 0,
			dbHoldbackValue: 0,
			dbHoldbackCalBasedOn: "",
			holdbackPercentBasedOn: "",

			// Vehicle Cost Calculation
			costFromExcede: 0,
			manualOverride: 0,
			finalSellingPrice: 0,
			inventoryCost: 0,
			holdbackFixed: 0,
			holdbackPercent: 0.00,
			holdback: 0,
			customHoldback: false,
			totalCostOfFetEligibleAddOrPO: 0,
			costSubTotal: 0,
			truckMarginPercent: 0,
			truckMarginAmount: 0,
			otherFetAppMarkups: 0,
			truckMargin: 0,
			overallowance: 0,
			baseSalesPrice: 0,
			tireCredits: 0,
			isFet: false,
			fetExemptItems: 0,
			fet: 0,
			isToCustomer: false,
			isToDealer: true,
			truckSellingPriceInclFet: 0,
			totalCostOfFees: 0,
			totalCostOfNonFetAddOrPO: 0,
			totalSellingPriceInclFet: 0,
			stateTaxRate: 0,
			stateTaxAmount: 0,
			customTaxRate: 0,
			customTaxAmount: 0,
			requiredFieldInfo: "REQUIRED FIELD",
			viewTotalFeesFormula: `Doc Preparation Fee + License Title +
								Tire Fees + Admin Fee +
								Registration + Smog Certification or Exemption +
								DMV Electronic Fee + Smog Fee Paid To Seller + IMF/Sales Tax +
								Deal Adjustment NC + TN Business Tax + Fleet CMA Cost`,
		tradeDetailModalInstance: null,
		addOnListModalInstance: null,
		purchaseOrderModalInstance: null,
		searchVinModalInstance: null,
		searchUnitModalInstance: null,
		calculateFlooringModalInstance: null,
		costSheetModalInstance: null,
	    isSplitCommission: false,
		isNoCommission: false,
		grossProfit: 0,
		lessHoldback: 0,
		comOverAllowance: 0,
		amtExcl: 0,
		grossProfitPercentageToSales: 0.00,
		grossProfitAfterHoldback: 0,
		commissionRate: 0.00,
		commissionPercentage: 0.00,
		commissionAmount: 0,
		additionalCommission: 0,
		fandiBonusForSalesperson: 0,
		flatCommissionOnly: 0,
		totalCommission: 0,
		fniBonusPercentage: 0,
		isNewUsedDisabled: false,
		commOwner1: "",
		commOwner1Percent: 0.00,
		commOwner1Amount: 0,
		commOwner1Error: "",
		commOwner2: "",
		commOwner2Percent: 0.00,
		commOwner2Amount: 0,
		commOwner2Error: "",
		commOwner3: "",
		commOwner3Percent: 0.00,
		commOwner3Amount: 0,
		commOwner3Error: "",
		commOwner4: "",
		commOwner4Percent: 0.00,
		commOwner4Amount: 0,
		commOwner4Error: "",
		commOwner5: "",
		commOwner5Percent: 0.00,
		commOwner5Amount: 0,
		commOwner5Error: "",
		};
	},
	beforeMount() {
		self = this;
	},
	async mounted() {
		//Props
		if(self.cusStateTaxRate > 0 && self.cusApplySalesTax){
			self.stateTaxRate = self.cusStateTaxRate;
		}

		window.scrollTo(0, 0);
		/* Redirect to login page logic start */
		let userObj = localStorage.getItem("user_obj");
		if (userObj == null) {
			self.$router.push("/");
		}

		if(self.vehicleType !== "" && !self.isVehicleEdit) {
			self.typeId = {
			text: self.vehicleType
			}
		}

		self.showTrnsLoader = true;

		self.loadAllData(
			["FuelType", "Make", "Model"],
			false,
			self.loadVehicleInformationFields,
			"undefined",
			""
		);
		self.loadAllData(
			["User"],
			false,
			self.loadcommissionEstimateField,
			"undefined",
			""
		);
		self.labelMessages = self.allLabelMessages;
		self.staticMessages = self.allStaticMessages
		if (localStorage.getItem("AddOnTemp") != null) {
			localStorage.removeItem("AddOnTemp");
		}

		self.setActiveMenu("deals-list");
		EventBus.$emit("menu-selection-changed", "deals-list");
		
		/*###### Edit #####*/
		if (self.isVehicleEdit) {
			await self.getVehicleDataFromId(self.editAssetId);
		} else {
			self.isVinChange = true;
		}
		self.showTrnsLoader = false;
		
		self.newUsedCheck(true);
		self.initializeModals();

		//Default false for new truck (isFet),
		//Used truck team wants true (isFet).
		if ( self.typeId.text.toLowerCase() == 'used' ) {
			self.isFet = true;
		}

	},
	methods: { 

		initializeModals() {
			self.tradeDetailModalInstance = new bootstrap.Modal(
			document.getElementById("tradeDetailModal"),
				{
				backdrop: "static",
				keyboard: false,
				}
			);
			self.addOnListModalInstance = new bootstrap.Modal(
			document.getElementById("addOnModal"),
				{
				backdrop: "static",
				keyboard: false,
				}
			);
			self.purchaseOrderModalInstance = new bootstrap.Modal( 
			document.getElementById("vehiclePurchaseModal"),
				{
				backdrop: "static",
				keyboard: false,
				}
			);
			self.searchVinModalInstance = new bootstrap.Modal(
			document.getElementById("searchVinModal"),
				{
				backdrop: "static",
				keyboard: false,
				}
			);
			self.searchUnitModalInstance = new bootstrap.Modal(
			document.getElementById("searchUnitModal"),
				{
				backdrop: "static",
				keyboard: false,
				}
			);
			self.calculateFlooringModalInstance = new bootstrap.Modal(
			document.getElementById("calculateFlooring"),
				{
				backdrop: "static",
				keyboard: false,
				}
			);
			self.costSheetModalInstance = new bootstrap.Modal( 
			document.getElementById("defaultModal"),
				{
				backdrop: "static",
				keyboard: false,
				}
			);
		}, 
		expandCollapse: function (accordianPosition, positionNumber) {
			self[accordianPosition] = self[accordianPosition] == "vhc-collapse" ?  "vhc-expand" : "vhc-collapse";
			
			let expandCollapseImg = 'expandCollapseImg'+positionNumber;
			self[expandCollapseImg] = self[accordianPosition] == "vhc-collapse" ? "rotate-accordian-collapse" : "rotate-accordian-expand";
			return true;
		},

		incrementDecrementNumber(event, dataInstance) {
			switch (event.key) {
			case "ArrowUp":
				self[dataInstance] = parseFloat(self[dataInstance])+1;
				break;
			case "ArrowDown":
				self[dataInstance] = parseFloat(self[dataInstance])-1;
				break;
			}
		},

		checkUncheck: function (event, type) {
			if(event.target.checked) {
			self[type] = true;
			} else {
			self[type] = false;
			}

			switch(type) {
			case 'onInvoice':
				self.offInvoice = false;
				break;
			case 'offInvoice':
				self.onInvoice = false;
				break;
			case 'isToCustomer':
				self.isToDealer = false;
				self.handleTireCredit();
				break;
			case 'isToDealer':
				self.isToCustomer = false;
				self.handleTireCredit();
				break;
			case 'excludeAdminFee':
				self.handleExcludeAdminFee();
				break;
			case 'noLicense':
				self.handleNoLicense();
				break;
			case 'customHoldback':
				self.handleCustomHoldback();
				break;
			}

			self.vhcCostCalculation();
		},

		handleExcludeAdminFee() {
			if (self.excludeAdminFee) {
			self.adminFee = 0;
			self.sumFees();
			}
		},

		handleNoLicense() {
			self.licenseTitle = 0;
			self.sumFees();
		},

		handleCustomHoldback() {
			if (self.customHoldback === false) {
			//will be set from dbHoldbackCalculation 
			} else {
			self.holdbackFixed = 0;
			self.holdbackPercent = 0;
			}
		},

		handleTireCredit() {

			if(!self.isFet) {
				const totalForFetCalculation = self.baseSalesPrice;
				let fet = 0;
				fet = self.fetCalculation(totalForFetCalculation, self.overallowance);
				self.assignRemainingValue(fet,totalForFetCalculation);
			}
		},

		openCalendar() {
			self.$refs.dateInput.$el.focus()
		},

		newUsedCheck(isMount=false) {
			if(["310", "320", "330", "340"].includes(self.locationId.excedeLocId) && self.typeId.text.toLowerCase() == 'new') {
			self.isVisibleDDC = true;
			self.flooring = Math.max(0, self.initFlooring - self.deliveryDelayCredit); 
			} else {
			self.isVisibleDDC = false;
			self.flooring = Math.max(0, self.initFlooring);
			}

			if ( !self.isVehicleEdit ) {
				//Default false for new truck (isFet),
				//Used truck team wants true (isFet).
				if(self.typeId.text.toLowerCase() == 'used'){
					self.isFet = true;
				} else {
					self.isFet = false;
				}
			}
			
			if(!isMount) {
			self.getHoldbackCalculationData();
			}
		},

		searchVin() {
			self.isClickSearchVinModal = true;
			self.searchVinModalInstance.show();
		},

		searchUnit() {
			self.isClickSearchUnitModal = true;
			self.searchUnitModalInstance.show();
		},

		/*##### POPULATE VHC-INFO ON SELECT VIN #####*/
		populateAssetData(asset) {
			self.vin = asset.text;
			self.unitId = asset.unit;
			if (!(asset.text == "" || asset.text == undefined)) {
			self.manufacturerId = "";
			self.modelOEMId = "";
			self.fuelTypeId = "";
			self.odometer = "";
			self.vehicleDescription = "";
			self.ofTires = 0;
			self.year = "";
			self.color = "";
			self.bodyType = "";
			// self.scaleWeight = "";
			self.proposedDeliveryDate = "";
			self.populateFields();
			}
		},

		populateFields() {
			let config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("api_token"),
			},
			};
			self.showLoader();

			let data = new FormData();
			data.append("vinNo", self.vin);
			data.append("locId", self.locationId.value);
			data.append("unitId", self.unitId);
			HTTP.post("/get-asset-data-with-vinNo", data, config)
			.then(function (response) {
				self.hideLoader();
				if (response.data.status === "success") {
				let assetData = response.data.content.asset;

				self.vehicleId = assetData.id;
				self.unitId = assetData.unit; // populating the unit Id field
				if (assetData.make !== null) {
					self.manufacturerId = {
					text: assetData.make.name,
					value: assetData.make.id,
					};
				}

				self.year = assetData.year; //populating the year field
				if (assetData.model_type !== null) {
					self.modelOEMId = {
					text: assetData.model_type.name,
					value: assetData.model_type.id,
					};
				}

				self.color = assetData.exterior_color; // populating the color field

				const stockOrderType = self.stockOrderArr.filter((stockType) => {
					return stockType.value == assetData.is_stock;
				});

				self.stockOrder = stockOrderType[0];

				if (assetData.body_type !== null) {
					self.bodyType = assetData.body_type.name; // populating the body Type field
				}

				self.typeId = {
					text: assetData.vehicle_type
				}// populating the N/U field

				if (assetData.fuel_type !== null) {
					const fuelTypeConst = self.fuelTypeArr.filter((fuelType) => {
					return fuelType.text == assetData.fuel_type.name;
					});
					self.fuelTypeId = fuelTypeConst[0];
				}

				self.odometer = assetData.odometer; // populating the odometer field

				// self.scaleWeight = assetData.empty_weight; // populating the scaleWeight field

				self.vehicleDescription = assetData.description; // populating the vehicle Description field

				if(self.ofTires == 0 && assetData.tireConfig != null) {
					self.ofTires = assetData.tireConfig;
				}

				const stockTypeConst = self.stockTypeArr.find(stockType => stockType.value === assetData.is_truck);
				self.stockTypeId = !stockTypeConst ? "" : stockTypeConst;

				self.handleVin();

				self.initialCal = true;
				const holdbackCalculationData = response.data.content.holdbackCalculationData;
				self.assigneHoldbackCalculationValues(holdbackCalculationData);

				self.sumFees();
				}
			})
			.catch(function (err) {
				self.hideLoader();
				console.log("ERROR!!! 2060", err);
			});
		},

		loadVehicleInformationFields: function (loadVehicleInformationCallback) {
			loadVehicleInformationCallback.FuelType.map((fuel) => {
			self.fuelTypeArr.push(fuel);
			});

			loadVehicleInformationCallback.Make.map((manufacture) => {
			self.manufacturerArr.push(manufacture);
			});

			loadVehicleInformationCallback.Model.map((model) => {
			self.modelOEMArr.push(model);
			});
		},
		loadcommissionEstimateField(callbackData) {
			self.salesmanArr = callbackData.User;
		},

		/*##### Tire Fee Calculation #####*/
		calTireFee() {
			try {
			var noTires = parseFloat(self.ofTires);
			var tireTax = parseFloat(self.tireTax);
			var tireFee = parseFloat(self.tireFee);
			if (noTires > 0 && tireTax > 0) {
				self.tireFee = parseFloat(noTires * tireTax);
			} else if (noTires > 0 && tireFee > 0) {
				self.tireTax = parseFloat(tireFee / noTires);
			}
			} catch (error) {
			console.log('error=>',error);
			}
		},

		/*##### FEES TOTAL #####*/
		sumFees: function (isEdit=false) {

			self.calTireFee();

			const total =
			parseFloat(self.docPreparation, 2) +
			parseFloat(self.licenseTitle, 2) +
			parseFloat(self.tireFee, 2) +
			parseFloat(self.adminFee, 2) +
			parseFloat(self.registration, 2) +
			parseFloat(self.smogCertificationOrExemptionFeePaidToState, 2) +
			parseFloat(self.dmvElectronicFillingFee, 2) +
			parseFloat(self.smogFeePaidToSeller, 2) +
			parseFloat(self.imfSalesTax, 2) +
			parseFloat(self.dealAdjustment, 2) +
			parseFloat(self.tnBusinessTax, 2) +
			parseFloat(self.fleetCmaCost, 2);
			self.feesTotal = total;
			self.totalCostOfFees = total;
			if(!isEdit) {
			self.vhcCostCalculation();
			}
		},

		finalSellingPriceCalc(){
			// Final Selling Price only for 
			if(self.typeId.text.toLowerCase() == 'used' && !self.customHoldback){
			self.getHoldbackCalculationData();
			} else if(self.typeId.text.toLowerCase() == 'used' && self.customHoldback){
			self.vhcCostCalculation();
			}
		},

		/*##### Vehcile Cost Calculation #####*/
		vhcCostCalculation(holdbackType="holdbackPercent", truckMarginType="truckMarginPercent") {
			
			// Retrieve and parse necessary values from the data instance
			let inventoryCost = 0;
			if(self.isManualOverride) {
			inventoryCost = parseFloat(self.manualOverride);
			} else{
			inventoryCost = parseFloat(self.costFromExcede);
			}
			self.inventoryCost = inventoryCost;
			
			// if(holdbackType==="reCalculate" && self.manualOverride===self.inventoryCost && (self.truckMarginAmount+self.otherFetAppMarkups === self.truckMargin)) {
			//   return true;
			// } // Note: Refactor or remove this reCalculate and avoidHoldback logic in coming days        

			// Calculate the Holdback
			if(holdbackType!=="avoidHoldback" && holdbackType!=="reCalculate") {
			self.holdbackCalculation(holdbackType);
			}
			const holdback =  parseFloat(self.holdback);

			const totalCostOfFetEligibleAddOrPO = parseFloat(self.totalCostOfFetEligibleAddOrPO); //Addons Cost

			// Calculate the cost subtotal
			const costSubTotal = inventoryCost+holdback+totalCostOfFetEligibleAddOrPO;

			// Retrieve and parse truck margin and other markups
			self.truckMarginCalculation(truckMarginType, costSubTotal, true, holdbackType);
		},
		
		/*##### FET Calculation #####*/
		fetCalculation(totalForFetCalculation, overallowance) {
			const fetExemptItems = parseFloat(self.fetExemptItems);
			const subTotal = totalForFetCalculation-fetExemptItems-overallowance;
			const totalForFetCalculationPercentAmount = (subTotal * 12) / 100; // 12% is the static value as per client
			const tireCredits = parseFloat(self.tireCredits);
			if(self.isToDealer) {
			return totalForFetCalculationPercentAmount-tireCredits;
			}else if(self.isToCustomer){
			return totalForFetCalculationPercentAmount;
			}
		},

		/*##### Holdback Calculation #####*/
		holdbackCalculation(type) {
			//Temp Holdback Type
			self.holdbackType = type;
			if(self.customHoldback) {
			let calcBasedOn = '';
			if(self.typeId.text.toLowerCase() == 'used'){
				calcBasedOn = 'finalSellingPrice';
			}else{
				calcBasedOn = 'factoryPrice';
			}
			if(type=='holdbackPercent' && self.holdbackPercent > 0) {
				const holdbackPercent = parseFloat(self.holdbackPercent);
				const holdback = (self[calcBasedOn]*holdbackPercent)/100;
				self.holdback = holdback;
				self.holdbackFixed = holdback;
			} else {
				const holdbackFixed = parseFloat(self.holdbackFixed);
				const holdbackPercent = self[calcBasedOn] > 0 ? (holdbackFixed / (self[calcBasedOn]/100)) : 0;
				self.holdbackPercent = holdbackPercent;
				self.holdback = holdbackFixed;
			}
			return true;
			}
			if(self.dbHoldbackPercent>0 || self.dbHoldbackValue>0) {
			let holdback = 0;
			if(self.dbHoldbackPercent>0) {
				const holdbackPercent = parseFloat(self.dbHoldbackPercent)/100;
				holdback = holdbackPercent*self[self.dbHoldbackCalBasedOn];
			} else {
				holdback = parseFloat(self.dbHoldbackValue);
			}

			self.holdback = holdback;
			self.holdbackFixed = holdback;
			self.holdbackPercent = self.dbHoldbackPercent;
			} else {
			self.holdback = 0;
			self.holdbackFixed = 0;
			self.holdbackPercent = 0;
			}
		},

		/*##### Truck Margin Calculation #####*/
		truckMarginCalculation(type, calCostSubTotal=0, isVehicleCal=false, holdbackType="holdbackPercent") {
			
			if (self.typeId.text.toLowerCase() == 'used') {

				const finalSellingPrice = parseFloat(self.finalSellingPrice);

				//Set Cost Sub Total
				let inventoryCost = 0;
				if(self.isManualOverride) {
					inventoryCost = parseFloat(self.manualOverride);
				} else{
					inventoryCost = parseFloat(self.costFromExcede);
				}
				self.inventoryCost = inventoryCost;
				const holdback =  parseFloat(self.holdback);
				const totalCostOfFetEligibleAddOrPO = parseFloat(self.totalCostOfFetEligibleAddOrPO); //Addons Cost
				const costSubTotal = inventoryCost+holdback+totalCostOfFetEligibleAddOrPO;
				self.costSubTotal = costSubTotal;
				
				//Set Truck Margin Amount
				const truckMargin = finalSellingPrice - costSubTotal;
				self.truckMarginAmount = truckMargin;
				self.truckMargin = truckMargin; 

				//Set Truck Margin Percent
				const truckMarginPercent = costSubTotal > 0 ? (truckMargin/costSubTotal)*100 : 0;
				self.truckMarginPercent = truckMarginPercent;

				//Set Base Sales Price
				const totalForFetCalculation = costSubTotal + truckMargin + parseFloat(self.overallowance);
				self.baseSalesPrice = totalForFetCalculation;
				self.assignRemainingValue(0,totalForFetCalculation);

			} else {

				const costSubTotal = isVehicleCal ? calCostSubTotal : parseFloat(self.costSubTotal);
				const otherFetAppMarkups = parseFloat(self.otherFetAppMarkups);
				let truckMargin = 0;

				//If margin amount is not zero then consider it
				if ( self.truckMarginAmount > 0 ) {
					type = "truckMarginFixed";
				}

				if ( type == "truckMarginPercent" ) {
					const truckMarginPercent = parseFloat(self.truckMarginPercent)/100;
					truckMargin = costSubTotal * truckMarginPercent;
					self.truckMarginAmount = truckMargin;
				} else {
					truckMargin = parseFloat(self.truckMarginAmount);
					const truckMarginPercent = costSubTotal > 0 ? (truckMargin/costSubTotal)*100 : 0;
					self.truckMarginPercent = truckMarginPercent;
				}

				const overallowance = parseFloat(self.overallowance); // From Trade Form
				let totalForFetCalculation = self.baseSalesPrice;
				if ( type!=="reverseTruckMargin" ) {
					truckMargin = truckMargin+otherFetAppMarkups;
					self.truckMargin = truckMargin;
					totalForFetCalculation = costSubTotal+truckMargin+overallowance;
					self.baseSalesPrice = totalForFetCalculation;
				}

				// Note:- Refactor this logic in coming days after verification by client
				if ( holdbackType==="reCalculate" ) {
					self.vhcCostCalculation();
					return true;
				}
				
				// Fet Calculation
				let fet = 0;
				if ( !self.isFet ) {
					fet = self.fetCalculation(totalForFetCalculation, overallowance);
				}

				self.costSubTotal = costSubTotal;
				self.assignRemainingValue(fet,totalForFetCalculation);

			}
		},

		assignRemainingValue(fet,totalForFetCalculation) {

			const baseSalesPrice = parseFloat(self.baseSalesPrice);
			const stateTaxRate = parseFloat(self.stateTaxRate);
			const cusTaxRate = parseFloat(self.customTaxRate);
			const stateTaxAmount = (baseSalesPrice * stateTaxRate) / 100;
			const customTaxAmount = (baseSalesPrice * cusTaxRate) / 100;

			const truckSellingPriceInclFet = fet + totalForFetCalculation;
			const totalCostOfFees = parseFloat(self.totalCostOfFees); //Total Fees
			const totalCostOfNonFetAddOrPO = self.totalCostOfNonFetAddOrPO; // Non Fet Cost from Addon

			const totalSellingPriceInclFet = truckSellingPriceInclFet+totalCostOfFees+stateTaxAmount+customTaxAmount+totalCostOfNonFetAddOrPO;

			// Assign values to data properties
			self.stateTaxAmount = stateTaxAmount;
			self.customTaxAmount = customTaxAmount;
			self.fet = fet;
			self.truckSellingPriceInclFet = truckSellingPriceInclFet;
			self.totalSellingPriceInclFet = totalSellingPriceInclFet;

			self.calculateCommission();
		},

		/*##### Reverse Truck Margin From Total For Fet Calculation On Manual Entry #####*/
		reverseTruckMarginFromFetCalculation() {
			const totalForFetCalculation = parseFloat(self.baseSalesPrice);
			const overallowance = parseFloat(self.overallowance);
			const subTotal = parseFloat(self.costSubTotal);
			let truckMargin = 0;
			let truckMarginAmount = 0;
			const otherFetAppMarkups = parseFloat(self.otherFetAppMarkups);
			truckMargin = totalForFetCalculation - overallowance - subTotal;
			truckMarginAmount = truckMargin - otherFetAppMarkups;
			self.truckMarginAmount = truckMarginAmount;
			self.truckMargin = truckMargin;
			self.truckMarginCalculation('reverseTruckMargin');
		},

		/*##### Calculation Based on AddOn's & PO's 'EXCL FROM FET' #####*/
		addOnPoListCal(isEdit=false) {
			let fetIncludeCostTotal = 0;
			let fetExcludeCostTotal = 0;
			let fetExcludePriceTotal = 0;
			if(self.purchaseOrderList.length > 0) {
			const poTempItemSave = self.purchaseOrderList.map(poItem => poItem.po_items).flat();
			self.addOnPoList = [...self.addOnItems,...poTempItemSave];
			} else {
			self.addOnPoList = self.addOnItems;
			}
			self.addOnPoList.map((list) => {
			let cost = parseFloat(list.cost);
			let price = parseFloat(list.price);

			//OLD CODE
			// if(!list.is_fet_exempt) {
			//   fetIncludeCostTotal += cost;
			// } else {
			//   fetExcludeCostTotal += cost;
			//   fetExcludePriceTotal += price;
			// }
			
			//Used Truck team don't want to consider MISC cost in calculation (2024-09-26)
			//As per the used truck team now they want to consider MISC Cost (2024-10-10) [C-MISC Condition Removed]
			if (self.typeId.text.toLowerCase() == 'used') { //For Used Truck Team
				if(cost>0){
					fetIncludeCostTotal += cost;
				}
				if(price>0){
					fetExcludeCostTotal += price;
				}
				
			} else {
				//Updated on 21 Aug 2024
				if(cost>0 && price>0 && list.is_fet_exempt){ 
					fetExcludeCostTotal += price; // Total of Non-FET Add's/PO's (Off-Invoice)
				} else if(price>0 && cost == 0 && list.is_fet_exempt) {
					fetExcludePriceTotal += price; // On-Invoice FET Exempt Items
				} else {
					fetIncludeCostTotal += cost; // Total of FET Eligible Add's/PO's
				}
			}
			
			});
			self.totalCostOfFetEligibleAddOrPO = fetIncludeCostTotal;
			self.fetExemptItems = fetExcludePriceTotal;
			self.totalCostOfNonFetAddOrPO = fetExcludeCostTotal;
			const holdbackType = self.holdback === 0 ? "holdbackPercent" : "holdbackFixed";
			self.vhcCostCalculation(holdbackType, 'truckMarginAmount');
		},

		/*##### AddOn #####*/
		addOnModal: function () {
			self.isClickAddOnList = true;
			self.addOnEditData = {};
			self.addOnAssetId = (self.editAssetId == 0 || self.editAssetId == null) ? self.dealInventoryMapperId : self.editAssetId;
			self.addOnListModalInstance.show();
		},

		/*##### PURCHASE ORDER #####*/
		purchaseOrderModal: function () {

			if (self.locationId == "") {

				self.showSAlert(
					self.staticMessages.vws_select_brn_frm_trns,
					"info",
					false,
					2
				);
			} else if (self.vin == "") {
				self.showSAlert(self.staticMessages.vws_select_inventory, "info", false, 2);
			} else {
				self.poEditAssetId = (self.editAssetId == 0 || self.editAssetId == null) ? self.dealInventoryMapperId : self.editAssetId;
				self.poEditData = {};
				self.isClickPurchaseOrder = true;
				self.purchaseOrderModalInstance.show();
			}
		},

		accrueOpenPosModal: function () {
			self.$refs.accrueOpenPosModalRef.openModal();
		},

		deleteAddOnItem(data,index,deleteType,parentIndex) {
			self.$swal({
			title: '',
			text: 'Are you sure that you want to delete the Add On Item!',
			type: 'info',
			confirmButtonText: 'OK',
			showCancelButton: true,
			showLoaderOnConfirm: true
			}).then((result) => {
				if (result.value) {
				self.showTrnsLoader = true;
				// If it stored items then we delete from DB
				if(Object.keys(data).includes('id') && data.id !== null) {
					const id = data.id;
					let config = {
					headers: {
						Authorization: "Bearer " + localStorage.getItem("api_token"),
					},
					data : {
						purchase_order_id: data.purchase_order_id,
						// ex_itm_id: data.ex_itm_id
					}
					};
					HTTP.delete("vehicle-worksheet/addon-list/item/"+id, config).then((response) => {
						if(response.data.status === "success") {
							self.showSAlert(
								response.data.message,
								response.data.status,
								false,
								2
							);
							self.poEditAssetId = (self.editAssetId == 0 || self.editAssetId == null) ? self.dealInventoryMapperId : self.editAssetId;
							self.getItemList();
						}
						self.showTrnsLoader = false;
					})
					.catch((err) => {
						self.showTrnsLoader = false;
						this.catchHandler(err, function () {});
					});

				} else {

					if(deleteType=="po-item") {
						self.purchaseOrderList[parentIndex].po_items.splice(index, 1);
						self.purchaseOrderList[parentIndex].po_items_count = self.purchaseOrderList[parentIndex].po_items_count-1;
					} else {
						self.addOnItems.splice(index, 1);
						self.$nextTick(() => {
							localStorage.setItem("AddOnTemp", JSON.stringify(self.addOnItems));
						})
					}
					self.addOnPoListCal();
					self.showTrnsLoader = false;
				}
				}
			});
		},

		deletePO : function (id,purId) {
			self.$swal({
				title: '',
				text: 'Are you sure that you want to delete this PO!',
				type: 'info',
				confirmButtonText: 'OK',
				showCancelButton: true,
				showLoaderOnConfirm: true
			}).then((result) => {
			if (result.value) {
				self.showTrnsLoader = true;
				var config = {
					headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
					params : {
						pur_id: purId,
					}
				};
				HTTP.delete("/purchase-order/"+id, config)
				.then(function (response) {
					if (response.data.code == '200') {
						self.poEditAssetId = (self.editAssetId == 0 || self.editAssetId == null) ? self.dealInventoryMapperId : self.editAssetId;
						self.getItemList();
						self.showSAlert(response.data.message, 'success', false, 2);
					}
				})
				.catch(function (error) {
				self.catchHandler(error, function () {});
				})
				.finally(function() {
				self.showTrnsLoader = false;
				})
			}
			});
		},

		getItemList() {
			self.isGetItemApiCall = true;
			let config = {
				headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
			};
			HTTP.get("/vehicle-worksheet/addon-list/item/"+self.poEditAssetId, config)
				.then(function (response) {

					if (response.data.code == '200') {

						self.isGetItemApiCall = false;
						self.showTrnsLoader = false;
						self.addOnItems = response.data.content.listAddonItems;
						self.purchaseOrderList = response.data.content.listPurchaseOrderItems;
						const holdbackType = self.holdback === 0 ? "holdbackPercent" : "holdbackFixed";
						self.vhcCostCalculation(holdbackType, 'truckMarginAmount');
						self.addOnPoListCal();
					}
				})
				.catch(function (error) {
					self.showTrnsLoader = false;
					self.isGetItemApiCall = false;
					self.catchHandler(error, function () {});
				});
		},

		createPoExcede(poId) {
			self.$swal({
			title: '',
			text: self.staticMessages.vws_po_excede_confirm,
			type: 'info',
			confirmButtonText: 'OK',
			showCancelButton: true,
			showLoaderOnConfirm: true
			}).then((result) => {
				if (result.value) {
				self.showTrnsLoader = true;
				const config = {
					headers: {
					Authorization: "Bearer " + localStorage.getItem("api_token"),
					},
				};
				const data = {
					purchase_order_id: poId,
				};
				HTTP.post("po/excede", data, config).then((response) => {
					if(response.data.status === "success") {
						self.poEditAssetId = (self.editAssetId == 0 || self.editAssetId == null) ? self.dealInventoryMapperId : self.editAssetId;
						self.getItemList();
						self.showMailModal(response.data.content);
					} else {
						
						if ( response.status===204) {
							self.showSAlert(
								self.staticMessages.vws_po_excede_unregistered,
								"error",
								false,
							);
						}
					}
					self.showTrnsLoader = false;
				})
				.catch((err) => {
					console.log("error=>", err);
					self.showTrnsLoader = false;
					self.catchHandler(err, function () {});
				});
				}
			});
		},

		showMailModal(data) {

			self.isClickMail = true;
			self.mailExcedePurId = data.purId;
			const countItems = data.po_items.length;
			self.mailDescription = `
				<p>The following item(s) have been authorized for stock number ${data.unit}.</p>
				<p>VIN: ${data.vin}</p>
				<p>Description: ${data.summary}</p>
				<p>Vendor: ${data.vendor_name}</p>
				<p>PO#: ${data.purId}</p>
			`;

			for (let i = 0; i < countItems; i++) {
				self.mailDescription += `
					<p>Line ${i + 1}: ${data.po_items[i].description}</p>
					<p>Cost: ${data.po_items[i].cost}</p>
				`;
			}

			self.mailDescription += `
				<p>Buyer: ${self.customer}</p>
				<p>Deal No: ${self.dealNo}</p>
				<p>Sales Person: ${self.salesperson}</p>
			`;
			$("#mailModal").modal({ backdrop: "static", keyboard: false });
		},

		/*##### TRADE DETAIL #####*/
		tradeDetailModal(type) {
			self.isClickAddTrade = true;
			self.currentVehicleWorksheetId = self.vehicleDataId !== null ? self.vehicleDataId : self.dealInventoryMapperId;
			self.isEditTradeDetail = false;
			if(type == 'Edit') {
			self.isEditTradeDetail = true;
			}
			self.tradeDetailModalInstance.show();
		},

		/*##### COST SHEET #####*/
		vehicleCostSheet: function () {
			if (self.vin == "") {
			self.showSAlert(self.staticMessages.vws_select_inventory, "info", false, 2);
			} else {
			self.isClickCostSheet = true;
			self.costSheetModalInstance.show();
			}
		},

		/*##### FLOORING CALCULATION #####*/
		calFlooring: function (type='factoryPrice') {
			self.isClickCalFlooring = true;
			self.flooringType = type;
			self.flooringTypeValue = self[type];
			if(type=='bodyCost') {
			self.flooring = self.bodyFlooring;
			self.flooringStartDate = self.bodyFlooringStartDate;
			self.flooringEndDate = self.bodyFlooringEndDate;
			self.flooringRate = self.bodyFlooringRate;
			} else {
			self.flooring = self.chassisFlooring;
			self.flooringStartDate = self.chassisFlooringStartDate;
			self.flooringEndDate = self.chassisFlooringEndDate;
			self.flooringRate = self.chassisFlooringRate;
			}
			self.calculateFlooringModalInstance.show();
		},

		/*##### VALIDATION #####*/
		validForm() {
			let scrollTo = "";
			let valid = true;

			if(self.vehicleType !== "" && self.vehicleType !== self.typeId.text) {
			self.showSAlert(
				"The Vehicle Type Should Be "+self.vehicleType+" !",
				"info",
				false,
				2
			);
			return false;
			}

			if (!self.manufacturerId) {
				self.manufacturerError = self.staticMessages.vws_validation_message;
				if (valid) {
					valid = false;
				}
			} else {
				self.manufacturerError = '';
			}
			
			if (!self.stockOrder) {
				self.stockOrderError = self.staticMessages.vws_validation_message;
				if (valid) {
					valid = false;
				}
			} else {
				self.stockOrderError = '';
			}

			if (!self.modelOEMId) {
				self.modelError = self.staticMessages.vws_validation_message;
				if (valid) {
					valid = false;
				}
			} else {
				self.modelError = '';
			}

			if (!self.vin) {
				self.vinError = self.staticMessages.vws_validation_message;
				if (valid) {
					valid = false;
				}
			} else {
			if(self.usedVinList.includes(self.vin)) {
				self.vinError = "VIN already used!";
				valid = false;
			} else {
				self.vinError = '';
			}
			}

			if(self.stockOrder && self.stockOrder.value === "stock") {
			if(self.vehicleId === null) {
				self.vinError = "Please search & select VIN";
				valid = false;
			}
			}

			if(self.usedUnitList.includes(self.unitId)) {
			self.unitError = "Unit ID already used!";
			valid = false;
			}

			// Validate like if duplicate commission owner is selected or not
			let finalCommOwners = [];
			const commOwners = [
			{ owner: self.commOwner1, errorKey: 'commOwner1Error', percentageKey: 'commOwner1Percent', amountKey: 'commOwner1Amount' },
			{ owner: self.commOwner2, errorKey: 'commOwner2Error', percentageKey: 'commOwner2Percent', amountKey: 'commOwner2Amount' },
			{ owner: self.commOwner3, errorKey: 'commOwner3Error', percentageKey: 'commOwner3Percent', amountKey: 'commOwner3Amount' },
			{ owner: self.commOwner4, errorKey: 'commOwner4Error', percentageKey: 'commOwner4Percent', amountKey: 'commOwner4Amount' },
			{ owner: self.commOwner5, errorKey: 'commOwner5Error', percentageKey: 'commOwner5Percent', amountKey: 'commOwner5Amount' }
			];

			// Clear all commission owner errors initially
			commOwners.forEach(item => self[item.errorKey] = '');

			commOwners.forEach(({ owner, errorKey, percentageKey, amountKey }) => {
				if (owner?.value) {
					if (finalCommOwners.includes(owner.value)) {
						self[errorKey] = 'Duplicate Commission Owner';
						scrollTo = 'split-commission';
						valid = false;
					} else {
						finalCommOwners.push(owner.value);
					}
				}else{
					//Reset percent and amount if owner is null
					self[percentageKey] = 0;
					self[amountKey] = 0;
				}
			});

			if (scrollTo != '' && valid == false) {
				document
				.getElementById(scrollTo)
				.scrollIntoView({ behavior: "smooth", block: "start" });
			}
			
			return valid;
		},

		/*##### SAVE #####*/
		saveCloseWorkSheet: async function () {
			if (self.validForm()) {
			self.showTrnsLoader = true;
			if (
				self.dealId !== null &&
				(self.dealId !== 0) && (self.dealId !== undefined)
			) {
				await self.saveDealInventory();
				self.storeAddOnPoInBulk();
				if(!self.isTradeSave && self.tradeDetailsData !== null) {
				self.storeTradeDetailsInBulk();
				}
				/* TOBE CONSIDER
				EventBus.$emit(
				"cash-details-data",
				self.stateTaxAmount,
				self.customTaxAmount,
				self.cashDetailWarranty
				);
				*/
				self.showTrnsLoader = false;
				self.saveNotNull = true;
				if (self.vehicleData == "") {
				localStorage.setItem(
					"dealInventoryMapperId",
					self.dealInventoryMapperId
				);
				localStorage.setItem("vehicleId", self.vehicleId);
				}
			} else {
				self.saveNotNull = false;
			}
			self.showTrnsLoader = false;
			}
		},

		saveDealInventory: function () {
			try {
			self.isNewUsedDisabled = true;
			const config = {
				headers: {
				Authorization: "Bearer " + localStorage.getItem("api_token"),
				},
			};
			let data = new FormData();
			// foreign keys
			data.append("deal_id", self.dealId);
			data.append("inventory_id", self.vehicleId !== null ? self.vehicleId : null);
			data.append("makeId", self.manufacturerId.value);
			data.append("modelId", self.modelOEMId.value);
			
			/*##### VHC INFO SECTION #####*/
			data.append('new_used', self.typeId.text);
			data.append("vin", self.vin);
			data.append('unit', self.unitId);
			
			data.append('year', self.year);
			data.append("manufacturer", self.manufacturerId.text);
			data.append('model', self.modelOEMId.text);
			data.append('color', self.color);
			
			data.append('odometer', self.odometer);
			data.append('stock_type', (self.stockTypeId != null && self.stockTypeId != '' && self.stockTypeId != 'undefined') ? self.stockTypeId.value : null);
			data.append('body_type', self.bodyType);
			data.append("gvw", self.gvwGCWR);
			
			data.append('quantity', self.quantity);
			data.append('hours', self.hours);
			data.append('fuel_type', (self.fuelTypeId != null && self.fuelTypeId != '' && self.fuelTypeId != 'undefined') ? self.fuelTypeId.text : null );
			data.append("of_tires", self.ofTires);
			
			data.append("prop_delivery_date", self.proposedDeliveryDate);
			data.append('duty', (self.duty != null && self.duty != '' && self.duty != 'undefined') ? self.duty.value : null);
			data.append('stock_or_order', self.stockOrder != null && self.stockOrder != '' && self.stockOrder != 'undefined' ? self.stockOrder.value : null);

			// Fees
			data.append("doc_prepare_fee", self.docPreparation);
			data.append("admin_fee", self.adminFee);
			data.append("is_on_invoice", self.onInvoice);
			data.append("is_off_invoice", self.offInvoice);
			data.append("is_exclude_admin_fee", self.excludeAdminFee);
			data.append("deal_adjustment_nc", self.dealAdjustment);

			data.append("license_title", self.licenseTitle);
			data.append("is_customer_fee", self.customFee);
			data.append("is_no_license", self.noLicense);
			data.append("registration", self.registration);
			data.append("dmv_electronic_filling_fee", self.dmvElectronicFillingFee);
			data.append("imf_sales_tax", self.imfSalesTax);
			data.append("tn_business_tax", self.tnBusinessTax);

			data.append("tire_fee", self.tireFee);
			data.append("smog_cert_pay_to_state", self.smogCertificationOrExemptionFeePaidToState);
			data.append("smog_fee_paid_to_seller", self.smogFeePaidToSeller);
			data.append("fleet_cma_ctrl", self.fleetCmaCtrl);
			data.append("fleet_cma_cost", self.fleetCmaCost);

			data.append("finance_reserved", self.financeReserve);
			data.append("body_cost", self.bodyCost);
			data.append("adot_permit", self.adotPermit);
			data.append("customer_supply_body", self.customerSuppliedBody);

			data.append("factory_invo_date", self.factoryInvDate);
			data.append("hold_date", self.holdDate);
			data.append("factory_price", self.factoryPrice);
			data.append("chassis_flooring", self.chassisFlooring);
			data.append("chassis_flooring_start_date", self.chassisFlooringStartDate);
			data.append("chassis_flooring_end_date", self.chassisFlooringEndDate);
			data.append("chassis_flooring_rate", self.chassisFlooringRate);
			data.append("body_flooring", self.bodyFlooring);
			data.append("body_flooring_start_date", self.bodyFlooringStartDate);
			data.append("body_flooring_end_date", self.bodyFlooringEndDate);
			data.append("body_flooring_rate", self.bodyFlooringRate);

			// Vehicle Cost Calculation
			data.append("cost_from_excede", self.costFromExcede);
			data.append("manual_override", self.manualOverride);
			data.append("inventory_cost_last_updated", self.inventoryCost);
			data.append("is_manual_override", self.isManualOverride);
			data.append("final_selling_price", self.finalSellingPrice);

			data.append("hold_back_fixed", self.holdbackFixed);
			data.append("custom_hold_back", self.customHoldback);
			data.append("holdback_percentage", self.holdbackPercent);
			
			data.append("truck_margin_percent", self.truckMarginPercent);
			data.append("truck_margin_amount", self.truckMarginAmount);
			data.append("other_fet_app_markups", self.otherFetAppMarkups);
			data.append("trade_amount", self.overallowance);
			data.append("base_sales_price", self.baseSalesPrice);


			data.append("tire_credit", self.tireCredits);
			data.append("is_fet", self.isFet);
			data.append("fet_exempt_items", self.fetExemptItems);
			data.append("fet", self.fet);

			data.append("state_tax_rate", self.stateTaxRate);
			data.append("state_tax_amt", self.stateTaxAmount);
			data.append("custom_tax_rate", self.customTaxRate);
			data.append("custom_tax_amt", self.customTaxAmount);

			/* Commission Estimate */
			data.append("gross_profit_amt", self.grossProfit);
			data.append("commission_less_holdback", self.lessHoldback);
			data.append("commission_our_allowance", self.comOverAllowance);
			data.append("commission_amt_excl", self.amtExcl);
			data.append("gross_profit_percentage_to_sales", self.grossProfitPercentageToSales),
			data.append("gross_profit_after_holdback", self.grossProfitAfterHoldback);
			data.append("commission_rate", self.commissionRate);
			data.append("commission_percentage", self.commissionPercentage),
			data.append("commission_amount", self.commissionAmount),
			data.append("additional_commission", self.additionalCommission);
			data.append("fi_bonus_for_salesperson", self.fandiBonusForSalesperson);
			data.append("flat_commission_only", self.flatCommissionOnly);
			data.append("total_commission", self.totalCommission);
			data.append("is_split_commission", self.isSplitCommission);
			data.append("is_no_commission", self.isNoCommission);
			data.append("comm_owner_1", self.commOwner1 != null && self.commOwner1.value != '' ? self.commOwner1.value : null);
			data.append("comm_owner_1_percent", self.commOwner1Percent);
			data.append("comm_owner_1_amount", self.commOwner1Amount);
			data.append("comm_owner_2", self.commOwner2 != null && self.commOwner2.value != '' ? self.commOwner2.value : null);
			data.append("comm_owner_2_percent", self.commOwner2Percent);
			data.append("comm_owner_2_amount", self.commOwner2Amount);
			data.append("comm_owner_3", self.commOwner3 != null && self.commOwner3.value != '' ? self.commOwner3.value : null);
			data.append("comm_owner_3_percent", self.commOwner3Percent);
			data.append("comm_owner_3_amount", self.commOwner3Amount);
			data.append("comm_owner_4", self.commOwner4 != null && self.commOwner4.value != '' ? self.commOwner4.value : null);
			data.append("comm_owner_4_percent", self.commOwner4Percent);
			data.append("comm_owner_4_amount", self.commOwner4Amount);
			data.append("comm_owner_5", self.commOwner5 != null && self.commOwner5.value != '' ? self.commOwner5.value : null);
			data.append("comm_owner_5_percent", self.commOwner5Percent);
			data.append("comm_owner_5_amount", self.commOwner5Amount);

			if (self.isVehicleEdit) {
				data.append("id", self.vehicleDataId);
			} else if (self.dealInventoryMapperId != null) {
				data.append("id", self.dealInventoryMapperId);
			}

			return new Promise(function (resolve, reject) {
				HTTP.post("/add/deal-inventory-mapper", data, config)
				.then(function (response) {
					const dealInventoryMapperResponse = response.data.content;
					self.dealInventoryMapperId = dealInventoryMapperResponse.id;
					self.showSAlert(
					response.data.message,
					response.data.status,
					false,
					2
					);
					self.isNewUsedDisabled = true;

					/**
					* 
					* REDIRECT TO VEHICLE WORKSHEET PAGE ON HARD REFRESH
					* Find the current vehicle in the localStorage
					* If not found add the current vehicle into the VehicleWorksheetRefresh
					* Else use same list
					* 
					*/
					let currentVhcRefreshList = [];
					currentVhcRefreshList = JSON.parse(localStorage.getItem('VehicleWorksheetRefresh'));
					let vhcRefreshList = [];

					if(currentVhcRefreshList && currentVhcRefreshList.length > 0) {
					const vehicleExist = currentVhcRefreshList.find(item => {
						return item.vehicleId === dealInventoryMapperResponse.id;
					});
					if(!vehicleExist) {
						currentVhcRefreshList.push({vehicleId: dealInventoryMapperResponse.id, status: 'edit', vehicleIndex: dealInventoryMapperResponse.count-1, dealId: self.dealId, permissionNameArray: self.permissionNameArray});
						vhcRefreshList = currentVhcRefreshList;
					} else {
						vhcRefreshList = currentVhcRefreshList;
					}
					} else {
					vhcRefreshList.push({vehicleId: dealInventoryMapperResponse.id, status: 'edit', vehicleIndex: dealInventoryMapperResponse.count-1, dealId: self.dealId, permissionNameArray: self.permissionNameArray});
					}
					localStorage.setItem('VehicleWorksheetRefresh', JSON.stringify(vhcRefreshList));
					/** END */
					
					resolve(response);
				})
				.catch(function (err) {
					self.catchHandler(err, function () {});
					self.hideLoader();
					reject(err);
					self.isNewUsedDisabled = false;
				});
			});
			} catch (error) {
			console.log("saveDealError=>", error);
			}
		},

		storeAddOnPoInBulk() {
			if (
			(localStorage.getItem("AddOnTemp") != null &&
			JSON.parse(localStorage.getItem("AddOnTemp")).length > 0) ||
			self.poTempData.length > 0
			) {
			const config = {
				headers: {
				Authorization: "Bearer " + localStorage.getItem("api_token"),
				},
			};

			let data = new FormData();
			data.append("data", localStorage.getItem("AddOnTemp"));
			data.append("deal_inventory_id", self.dealInventoryMapperId);
			let addOnLocal = false;
			if(localStorage.getItem("AddOnTemp") != null && JSON.parse(localStorage.getItem("AddOnTemp")).length > 0) {
				addOnLocal = true;
			}
			data.append("add_on_local", addOnLocal);
			if(self.poTempData.length > 0) {
				data.append("po_data", JSON.stringify(self.poTempData));
			}

			HTTP.post("store/add-on-po", data, config)
				.then(function (response) {
				if(response.data.status == "success") {
					localStorage.removeItem("AddOnTemp");
					self.poTempData = [];
					self.poEditAssetId = self.dealInventoryMapperId;
					self.getItemList();
				}
				return true;
				})
				.catch(function (err) {
				console.log("error=>", err);
				self.catchHandler(err, function () {});
				self.hideLoader();
				});
			}
			return true;
		},

		storeTradeDetailsInBulk() {
			self.tradeDetailsData.deal_inventory_mapper_id = self.dealInventoryMapperId;
			const config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("api_token"),
				},
			};
			HTTP.post("trade/details", self.tradeDetailsData, config).then((response) => {
			let content = response.data.content;
			self.tradeDetailsData = content;
			self.isTradeSave = true;
			}); 
			return true;
		},
		/*##### END #####*/

		/*##### EDIT #####*/
		getVehicleDataFromId: function (id) {
			const config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("api_token"),
			},
			params: {
				'edit': true,
			}
			};
			return new Promise(function (resolve, reject) { 
			HTTP.get("deal/"+id+"/inventory/mapper", config)
			.then(function (response) {
				const vehicleData = response.data.content;
				if(response.data.content.deal.branch.fni_bonus_perc != undefined) {
					self.fniBonusPercentage = response.data.content.deal.branch.fni_bonus_perc;
				}
				self.vehicleDataId = vehicleData.id;

				const currentDateFormat = process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT;
				
				/*##### VHC INFO SECTION #####*/
				self.typeId.text = vehicleData.new_used;
				self.vin = vehicleData.vin;
				self.unitId = vehicleData.unit;

				// COL-2
				self.year = vehicleData.year;
				self.manufacturerId = {
				text: vehicleData.manufacturer,
				value: vehicleData.make_id,
				};
				self.modelOEMId = {
				text: vehicleData.model,
				value: vehicleData.model_id,
				};
				self.color = vehicleData.color;

				// COL-3
				self.odometer = vehicleData.odometer;
				const stockTypeConst = self.stockTypeArr.filter((stockType) => {
				return stockType.value == vehicleData.service_plan;
				});
				self.stockTypeId = stockTypeConst[0];
				self.bodyType = vehicleData.body_type;
				self.gvwGCWR = vehicleData.gvw;

				// COL-4
				self.quantity = vehicleData.quantity;
				self.hours = vehicleData.hours;
				const fuelTypeConst = self.fuelTypeArr.filter((fuelType) => {
				return fuelType.text == vehicleData.fuel_type;
				});
				self.fuelTypeId = fuelTypeConst[0];
				self.ofTires = vehicleData.of_tires;

				// COL-5
				self.proposedDeliveryDate = vehicleData.prop_delivery_date
				? moment(vehicleData.prop_delivery_date).format(process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT)
				: "";
				const dutyConst = self.dutyArr.filter((duty) => {
				return duty.value == vehicleData.duty;
				});
				self.duty = dutyConst[0];
				const stockOrderConst = self.stockOrderArr.filter((stockOrder) => {
				return stockOrder.value == vehicleData.stock_or_order;
				});
				self.stockOrder = stockOrderConst[0];
				if(stockOrderConst[0]) {
				self.isStockVin = (stockOrderConst[0].value === 'stock') ? true : false;
				}

				/*##### FEES #####*/
				self.docPreparation = vehicleData.doc_prepare_fee;
				self.adminFee = vehicleData.admin_fee; //TOBE CHANGE
				self.onInvoice = false;
				if(vehicleData.is_on_invoice) { //TOBE CHANGE
				self.onInvoice = true;
				}
				self.offInvoice = false;
				if(vehicleData.is_off_invoice) { //TOBE CHANGE
				self.offInvoice = true;
				}
				self.excludeAdminFee = false;
				if(vehicleData.is_exclude_admin_fee) { //TOBE CHANGE
				self.excludeAdminFee = true;
				}
				self.dealAdjustment = vehicleData.deal_adjustment_nc;

				// COL-2
				self.licenseTitle = parseFloat(vehicleData.license_title);
				self.customFee = false;
				if(vehicleData.is_customer_fee) {
				self.customFee = true;
				}
				self.noLicense = false;
				if(vehicleData.is_no_license) {
				self.noLicense = true;
				}
				self.registration = vehicleData.registration;
				self.dmvElectronicFillingFee = vehicleData.dmv_electronic_filling_fee;
				self.imfSalesTax = vehicleData.imf_sales_tax; //TOBE CHANGE
				self.tnBusinessTax = vehicleData.tn_business_tax; //TOBE CHANGE

				// COL-3
				self.tireFee = vehicleData.tire_fee;
				self.smogCertificationOrExemptionFeePaidToState = vehicleData.smog_cert_pay_to_state;
				self.smogFeePaidToSeller = vehicleData.smog_fee_paid_to_seller;
				self.fleetCmaCtrl = vehicleData.fleet_cma_ctrl;
				self.fleetCmaCost = vehicleData.fleet_cma_cost;

				// COL-4
				self.financeReserve = vehicleData.finance_reserved;
				self.bodyCost = vehicleData.body_cost;
				self.adotPermit = vehicleData.adot_permit;
				self.customerSuppliedBody = parseFloat(vehicleData.customer_supply_body);

				// COL-5
				self.factoryInvDate = vehicleData.factory_invo_date ? moment(vehicleData.factory_invo_date).format(currentDateFormat) : "";
				self.holdDate = vehicleData.hold_date ? moment(vehicleData.hold_date).format(currentDateFormat) : "";
				self.factoryPrice = vehicleData.factory_price;
				self.chassisFlooring = vehicleData.flooring;
				self.chassisFlooringStartDate = vehicleData.flooring_start_date ? moment(vehicleData.flooring_start_date).format(currentDateFormat) : moment(new Date()).format(currentDateFormat);
				self.chassisFlooringEndDate = vehicleData.flooring_end_date ? moment(vehicleData.flooring_end_date).format(currentDateFormat) : moment(new Date()).format(currentDateFormat);
				self.chassisFlooringRate = vehicleData.flooring_rate ? vehicleData.flooring_rate : self.chassisFlooringRate;
				
				self.bodyFlooring = vehicleData.body_flooring;
				self.bodyFlooringStartDate = vehicleData.body_flooring_start_date ? moment(vehicleData.body_flooring_start_date).format(currentDateFormat) : moment(new Date()).format(currentDateFormat);
				self.bodyFlooringEndDate = vehicleData.body_flooring_end_date ? moment(vehicleData.body_flooring_end_date).format(currentDateFormat) : moment(new Date()).format(currentDateFormat);
				self.bodyFlooringRate = vehicleData.body_flooring_rate ? vehicleData.body_flooring_rate : self.bodyFlooringRate;

				/*##### VEHICLE COST CALCULATION #####*/
				self.costFromExcede = vehicleData.cost_from_excede; //TOBE CHANGE
				self.manualOverride = vehicleData.manual_override; //TOBE CHANGE
				self.isManualOverride = false;
				if(vehicleData.is_manual_override) {
				self.isManualOverride = true;
				}
				self.finalSellingPrice = vehicleData.final_selling_price;

				/* Commission Estimate */
				self.grossProfit = vehicleData.gross_profit_amt;
				self.lessHoldback = vehicleData.commission_less_holdback;
				self.comOverAllowance = vehicleData.commission_our_allowance;
				self.amtExcl = vehicleData.commission_amt_excl;
				self.grossProfitPercentageToSales = vehicleData.gross_profit_percentage_to_sales
				self.grossProfitAfterHoldback = vehicleData.gross_profit_after_holdback;
				self.commissionRate = vehicleData.commission_rate;
				self.commissionPercentage = vehicleData.commission_percentage,
				self.commissionAmount = vehicleData.commission_amount,
				self.additionalCommission = vehicleData.additional_commission;
				self.fandiBonusForSalesperson = vehicleData.fi_bonus_for_salesperson;
				self.flatCommissionOnly = vehicleData.flat_commission_only;
				self.totalCommission = vehicleData.total_commission;
				self.isSplitCommission = false;
				if(vehicleData.is_split_commission) {
					self.isSplitCommission = true;
				}
				self.isNoCommission = false;
				if(vehicleData.is_no_commission) {
				    self.isNoCommission = true;
				}
				self.commOwner1 = vehicleData.commission_owner1;
				self.commOwner1Percent = vehicleData.comm_owner_1_percent;
				self.commOwner1Amount = vehicleData.comm_owner_1_amount;
				self.commOwner2 = vehicleData.commission_owner2;
				self.commOwner2Percent = vehicleData.comm_owner_2_percent;
				self.commOwner2Amount = vehicleData.comm_owner_2_amount;
				self.commOwner3 = vehicleData.commission_owner3;
				self.commOwner3Percent = vehicleData.comm_owner_3_percent;
				self.commOwner3Amount = vehicleData.comm_owner_3_amount;
				self.commOwner4 = vehicleData.commission_owner4;
				self.commOwner4Percent = vehicleData.comm_owner_4_percent;
				self.commOwner4Amount = vehicleData.comm_owner_4_amount;
				self.commOwner5 = vehicleData.commission_owner5;
				self.commOwner5Percent = vehicleData.comm_owner_5_percent;				
				self.commOwner5Amount = vehicleData.comm_owner_5_amount;

				// ROW-2
				self.holdbackFixed = vehicleData.hold_back_fixed;
				self.customHoldback = false;
				if(vehicleData.custom_hold_back == "yes") {
				self.customHoldback = true;
				}
				self.holdbackPercent = vehicleData.percentage;

				// ROW-3
				self.truckMarginPercent = vehicleData.truck_margin_percent; //TOBE CHANGE 
				self.truckMarginAmount = vehicleData.truck_margin_amount; //TOBE CHANGE
				self.otherFetAppMarkups = vehicleData.other_fet_app_markups; //TOBE CHANGE

				// ROW-4
				self.tireCredits = vehicleData.tire_credit;
				self.isFet = false;

				if ( vehicleData.is_fet_exempt ) {
					self.isFet = true;
				}
				self.fetExemptItems = parseFloat(vehicleData.fet_examp_items);

				self.addOnItems = vehicleData.add_on_deals;
				self.purchaseOrderList = vehicleData.purchase_orders;

				const tradeDetailsData = vehicleData.trade_detail;
				if(tradeDetailsData !== null) {
				self.tradeDetailsData = tradeDetailsData;
				self.tradevalue = tradeDetailsData.trade_in_value; //NOT REQUIRED FOR NOW
				self.tradepayoff = tradeDetailsData.payoff; //NOT REQUIRED FOR NOW
				//Old Logic
				//self.overallowance = parseFloat(tradeDetailsData.trade_in_value)-parseFloat(tradeDetailsData.payoff);
				//New update on 27 Aug 2024
				self.overallowance = parseFloat(tradeDetailsData.over_allowance);
				self.isTradeSave = true;
				}

				self.vehicleId = vehicleData.inventory_id !== null ? vehicleData.inventory_id : null;
				self.getHoldbackCalculationData();
				self.sumFees(true);
				self.addOnPoListCal(true);
				resolve(response);
			})
			.catch(function (err) {
				console.log("ERROR!!! 3470", err);
				self.hideLoader();
			});
			});
		},
		/*##### END #####*/

		/*##### DELETE VEHICLEWORKSHEET #####*/
		deleteVehicleWorksheet : function () {
			if(self.permissionNameArray.includes('can_delete_deal_inventory_mapper')) {
			let id = null;
			if (self.isVehicleEdit) {
				id = self.vehicleDataId;
			} else if (self.dealInventoryMapperId != null) {
				id = self.dealInventoryMapperId;
			}

			if(id !== null) {
				self.deleteVehicleWorksheetApi(id);
			}
			}
		},

		deleteVehicleWorksheetApi(id) {
			self.$swal({
			title: '',
			text: 'Are you sure that you want to delete this Vehicle?',
			type: 'info',
			confirmButtonText: 'OK',
			showCancelButton: true,
			showLoaderOnConfirm: true
			}).then((result) => {
				if (result.value) {
					let config = {
						headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
					};
					HTTP.delete("/vehicle/worksheet/"+id, config)
						.then(function (response) {
							if (response.data.code == '200') {
							EventBus.$emit("delete-vheicle-worksheet", response.data, id);
							}
						})
						.catch(function (error) {
						console.log('error=>',error);
							self.catchHandler(error, function () {});
						});
				}
			});
		},
		
		/*##### CLOSE VEHICLE WORKSHEET #####*/
		closeWorkSheet: function () {
			if (self.saveNotNull === true) {
			EventBus.$emit(
				"trns-callback-after-saving",
				self.dealInventoryMapperId,
				self.vehicleId !== null ? self.vehicleId : self.vin
			);
			} else {
			EventBus.$emit("trns-callback-after-saving", 0, self.vehicleId);
			}
		},


		// AddOnItems
		editAddOnItem: function (data, index) {
			self.addOnEditData = data;
			self.isClickAddOnList = true;
			self.addOnTempIndex = index;
			self.addOnAssetId = (self.editAssetId == 0 || self.editAssetId == null) ? self.dealInventoryMapperId : self.editAssetId;
			self.addOnListModalInstance.show();
		},
		// PoItems
		editPoItem: function (data, index) {
			self.poEditAssetId = (self.editAssetId == 0 || self.editAssetId == null) ? self.dealInventoryMapperId : self.editAssetId;
			self.isClickPurchaseOrder = true;
			
			self.poEditData = data;
			self.purchaseOrderModalInstance.show();
		},

		getHoldbackCalculationData(fieldNameError=null) {
			self.showTrnsLoader = true;
			if(fieldNameError!==null) {
			self[fieldNameError] = "";
			}
			let config = {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("api_token"),
			},
			params: {
				'location_id': self.locationId.value,
				'condition': self.typeId.text,
				'manufacturer_id': self.manufacturerId.value,
				'model_id': self.modelOEMId.value,
				'asset_type': !self.stockTypeId ? null : self.stockTypeId.value,
			}
			};
			
			HTTP.get("holdback/calculation/data", config)
			.then((response) => {
			self.showTrnsLoader = false;
			if(response.data.code == 200 && response.data.status == "success") {
				const holdbackCalculationData = response.data.content;
				self.assigneHoldbackCalculationValues(holdbackCalculationData);
				self.vhcCostCalculation();
			}
			})
			.catch(function (err) {
			self.showTrnsLoader = false;
			self.catchHandler(err, function () {});
			});
		},

		assigneHoldbackCalculationValues(holdbackCalculationData) {
			let tempHoldbackPercent = 0;
			let tempHoldbackValue = 0;
			let tempHoldbackCalBasedOn = 0;
			let tempHoldbackPercentBasedOn = "";

			if(holdbackCalculationData !== null && holdbackCalculationData.cal_based_on==='finalSellingPrice') {
			self.holdback = 0;
			self.vhcCostCalculation('avoidHoldback');
			}

			if(holdbackCalculationData !== null) {
			tempHoldbackPercent = holdbackCalculationData.pct_val;
			tempHoldbackValue = holdbackCalculationData.amt_val;
			tempHoldbackCalBasedOn = holdbackCalculationData.cal_based_on;
			
			if(tempHoldbackCalBasedOn) {
				tempHoldbackPercentBasedOn = 'of '+self.convertToNormalText(tempHoldbackCalBasedOn);
			}
			}

			if(holdbackCalculationData == null && self.typeId.text.toLowerCase() == 'used') {
			tempHoldbackPercent = 1.5;
			tempHoldbackValue = 0;
			tempHoldbackCalBasedOn = 'finalSellingPrice';
			tempHoldbackPercentBasedOn = 'of Final Selling Price';
			}

			self.dbHoldbackPercent = tempHoldbackPercent;
			self.dbHoldbackValue = tempHoldbackValue;
			self.dbHoldbackCalBasedOn = tempHoldbackCalBasedOn;
			self.holdbackPercentBasedOn = tempHoldbackPercentBasedOn;
		},

		/**
		* Converts a camelCase field name from the `cal_based_on` column 
		* of the `vehicle_holdback_calculation` table
		* to Vehicle Worksheet Field Name
		* @param {string} fieldName Field name from the `cal_based_on` column.
		* @return {string} Formatted to field name in a Vehicle Worksheet
		*/
		convertToNormalText(fieldName) {
			if(fieldName==="finalSellingPrice") {
			return 'Final Selling Price';
			}else if(fieldName==="factoryPrice") {
			return 'Factory Cost';
			}
			return fieldName.replace(/([a-z])([A-Z])/g, '$1 $2')
					.replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
					.replace(/^./, function(str){ return str.toUpperCase(); });
		},

		includeManualOverrideCalculation(event) {
			if(event.target.checked) {
			self.isManualOverride = true;
			if(self.manualOverride > 0) {
				self.vhcCostCalculation('reCalculate');
			}
			} else {
			self.isManualOverride = false;
			self.vhcCostCalculation('reCalculate');
			}
		},

		handleVin(hasValue=null) {
			self.vinError = "";
			self.unitError = "";
			self.stockOrderError = "";
			self.manufacturerError = "";
			self.modelError = "";
			if(self.stockOrder.value === "stock" && self.vehicleId !== null) {
			self.isStockVin = true; 
			} else {
			self.isStockVin = false;
			}
			
			if(hasValue !== null) {
			self.clearVehicleInformation();
			self.$nextTick(() => {
				self.vhcCostCalculation();
			});
			}
		},

		clearVehicleInformation() {
			self.vin = "";
			self.vehicleId = null;
			self.unitId = "";
			const condition = (self.vehicleType !== "" && !self.isVehicleEdit) ? self.vehicleType : "New";
			self.typeId = {text: condition};
			self.year = "";
			self.manufacturerId = "";
			self.modelOEMId = "";
			self.color = "";
			self.odometer = "";
			self.stockTypeId = "";
			self.bodyType="";
			self.gvwGCWR=0;
			self.quantity = 1;
			self.hours = "";
			self.fuelTypeId = "";
			self.ofTires = 0;
			self.proposedDeliveryDate = "";
			self.duty = "";
		},

		calculateMargin() {

			self.truckMarginAmount = "0.00";
			self.truckMarginPercent = "0.0000";

			const totalSellingPrice = self.finalSellingPrice;
			const inventoryCost = self.inventoryCost;
			const holdBack = self.holdback;
			const totalCostOfFetEligibleAddOrPO = self.totalCostOfFetEligibleAddOrPO;
			const fetExItem = self.fetExemptItems;
			const tireCred = self.tireCredits;
			const overallowance = self.overallowance;
			const otherFetAppMarkups = self.otherFetAppMarkups;
			
			if ( totalSellingPrice == null || totalSellingPrice == "" || totalSellingPrice == 0 ) {
				alert("Please enter Final Selling Price first.");
				return;
			}
			// Initialize margin
			let margin = 0;

			// Loop until the total selling price matches the target value
			while (true) {
				// Calculate total sum
				const totalSum = inventoryCost + holdBack + totalCostOfFetEligibleAddOrPO + margin + otherFetAppMarkups + overallowance;
				
				// Calculate fet based on total sum
				const fet = (totalSum - fetExItem - overallowance) * 12 / 100;
				
				// Calculate total fet
				const totalFet = fet - tireCred;
				
				// Calculate total selling price
				const calculatedTotalSellingPrice = totalFet + totalSum;
				
				// Check if the calculated total selling price is close enough to the target
				if ( Math.abs(calculatedTotalSellingPrice - totalSellingPrice ) < 0.01 ) {
					break; // If close enough, exit the loop
				}

				// Increment margin to adjust the total selling price
				margin += 0.01; // You can change this step size for finer adjustments
			}
			
			var marAmt = margin + otherFetAppMarkups;
			self.truckMarginAmount = margin.toFixed(2);
			self.truckMargin = marAmt.toFixed(2);
			
			self.vhcCostCalculation('holdbackfixed','marginFixed');
			
		},
		calculateCommission(){

			// Closed Charges ( Exclude body) = ( Inventory cost - Factory cost ) - Body Cost
			// Flooring Cost = Body Flooring + Chassis Flooring 
			// Accrual Total = Factory Cost + Closed Charges ( Exclude body) + Open PO (Add on) (cost) + C-MISC (Cost) + Body cost (Added in Fees section ) + business Tax or Admin Fee Or Doc Fee ( If not added in sales price ) +/- warranty in addon  (if cost added and if price then deduct) + Fleet CMA cost + Total of incentive/Rebat C-inc (addon) (cost) total + total of ADA addon (price) (buswest only) + Fet credit addon total (cost) (C-FETC) + mudflap addon total (cost)
			
			// Gross Profit = Sales Price -  Accrual Total + Trade (over/under Allowance)
			// (Less Holdback) = Gross Profit - Holdback
			// Gross Profit % To Sales = (Gross Profit / (Sales Price - Body Cost ) ) * 100
			// Gross Profit After Holdback/MISC = ((Less Holdback) - Flooring Cost) + Total of 'E-FPE' addon (floor plan expense)(price) 

			let copoAddOnCost = 0;
			let cmiscAddOnCost = 0;
			let warAddOnCost = 0;
			let warAddOnPrice = 0;
			let cincAddOnCost = 0;
			let eadaAddOnPrice = 0;
			let cfetcAddOnCost = 0;
			let mudflapsAddOnCost = 0;
			let cbpurAddOnCost = 0;
			let efpeAddOnPrice = 0;
			self.addOnItems.map((list) => {
				if(list.description.includes("C-OPO")){
					copoAddOnCost += parseFloat(list.cost);
				}
				if(list.description.includes("C-MISC")){
					cmiscAddOnCost += parseFloat(list.cost);
				}
				if(list.description.includes("W-WAR")){
					warAddOnCost += parseFloat(list.cost);
					warAddOnPrice += parseFloat(list.price);
				}
				if(list.description.includes("C-INC")){
					cincAddOnCost += parseFloat(list.cost);
				}
				if(list.description.includes("E-ADA") && self.locationId.text.includes("BUSWEST")){
					eadaAddOnPrice += parseFloat(list.price);
				}
				if(list.description.includes("C-FETC")){
					cfetcAddOnCost += parseFloat(list.cost);
				}
				if(list.description.includes("MUDFLAPS")){
					mudflapsAddOnCost += parseFloat(list.cost);
				}
				if(list.description.includes("C-BPUR")){
					cbpurAddOnCost += parseFloat(list.cost);
				}
				if(list.description.includes("E-FPE")){
					efpeAddOnPrice += parseFloat(list.price);
				}
			});

			let closedCharges = (self.inventoryCost - self.factoryPrice) - self.bodyCost;
			let flooringCost = self.bodyFlooring + self.chassisFlooring;
			let accrualTotal = self.factoryPrice + closedCharges + copoAddOnCost + cmiscAddOnCost + self.bodyCost 
								// BusinessTax, Admin Fee, Doc Fee add only If not added in Sales Price (East Cost only) 
								// + self.tnBusinessTax + self.adminFee + self.docPreparation
								+ warAddOnCost - warAddOnPrice
								+ self.fleetCmaCost + cincAddOnCost + eadaAddOnPrice + cfetcAddOnCost + mudflapsAddOnCost
								+ cbpurAddOnCost;
			self.grossProfit = self.baseSalesPrice - accrualTotal + self.overallowance;
			self.lessHoldback = self.grossProfit - self.holdback;
			self.comOverAllowance = self.overallowance;

			if(self.baseSalesPrice - self.bodyCost >= 0) {
				self.grossProfitPercentageToSales = self.grossProfit / (self.baseSalesPrice - self.bodyCost) * 100;
			}else{
				self.grossProfitPercentageToSales = 0;
			}

			self.grossProfitAfterHoldback = (self.lessHoldback - flooringCost) + efpeAddOnPrice;

			let commPercent = 0;
			self.commissionPercentage = 35;
			if(self.commissionRate > 0){
				commPercent = self.commissionRate
				self.commissionPercentage = 0;
			}else{
				commPercent = self.commissionPercentage;
			}

			if(self.isNoCommission){
				// No Commission
				self.commissionAmount = 0;
				self.fandiBonusForSalesperson = 0;
				self.totalCommission =0;
			}else{
				// Apply Commission
				
				if(self.fniBonusPercentage > 0){
					self.fandiBonusForSalesperson = (self.financeReserve * self.fniBonusPercentage) / 100;
				}

				if(self.flatCommissionOnly > 0) {
					self.commissionAmount = 0;
					self.totalCommission = self.flatCommissionOnly + self.additionalCommission + self.fandiBonusForSalesperson;
				}else{
					self.commissionAmount = (self.grossProfitAfterHoldback * commPercent) / 100;
					self.totalCommission = self.commissionAmount + self.additionalCommission + self.fandiBonusForSalesperson;
				}
			}

			if(self.commOwner1Percent > 0){
				self.commOwner1Amount = (self.grossProfitAfterHoldback * self.commOwner1Percent) / 100;
			}
			if(self.commOwner2Percent > 0){
				self.commOwner2Amount = (self.grossProfitAfterHoldback * self.commOwner2Percent) / 100;
			}
			if(self.commOwner3Percent > 0){
				self.commOwner3Amount = (self.grossProfitAfterHoldback * self.commOwner3Percent) / 100;
			}
			if(self.commOwner4Percent > 0){
				self.commOwner4Amount = (self.grossProfitAfterHoldback * self.commOwner4Percent) / 100;
			}
			if(self.commOwner5Percent > 0){
				self.commOwner5Amount = (self.grossProfitAfterHoldback * self.commOwner5Percent) / 100;
			}			

		},
		resetCommOwnerPercent(key){
			if(key == 'commOwner1Amount'){
				self.commOwner1Percent = 0;
			} else if(key == 'commOwner2Amount'){
				self.commOwner2Percent = 0;
			} else if(key == 'commOwner3Amount'){
				self.commOwner3Percent = 0;
			} else if(key == 'commOwner4Amount'){
				self.commOwner4Percent = 0;
			} else if(key == 'commOwner5Amount'){
				self.commOwner5Percent = 0;
			}
		}
	},
};

EventBus.$on("close-search-vin", function (status) {
	if (typeof self != "undefined") {
	self.isClickSearchVinModal = status;
	self.searchVinModalInstance.hide();
	}
});

EventBus.$on("close-search-unit", function (status) {
	if (typeof self != "undefined") {
	self.isClickSearchUnitModal = status;
	self.searchUnitModalInstance.hide();
	}
});

EventBus.$on("get-search-vin", function (data) {
	if (typeof self != "undefined") {
	self.isClickSearchVinModal = false;
	self.searchVinModalInstance.hide();
	self.populateAssetData(data);
	}
});

EventBus.$on("get-search-unit", function (data) {
	if (typeof self != "undefined") {
	self.isClickSearchUnitModal = false;
	const getVin = {
		text: data.vin_no,
		unit: data.unit
	};
	self.searchUnitModalInstance.hide();
	self.populateAssetData(getVin);
	}
});

/*##### AddOn #####*/
EventBus.$on("add-on-data", function (data, type, isEdit) {
	if (typeof self != "undefined") {
	if(type == 'temp') {
		if(isEdit) {
		let addOnItemData = self.addOnItems;
		self.addOnItems = [];
		addOnItemData[self.addOnTempIndex] = data;
		self.$nextTick(() => {
			self.addOnItems = addOnItemData;
		})
		} else {
		self.addOnItems.push(data);
		}
		self.$nextTick(() => {
		self.addOnPoList = self.addOnItems;
		self.addOnPoListCal();
		})
	} else {
		self.poEditAssetId = (self.editAssetId == 0 || self.editAssetId == null) ? self.dealInventoryMapperId : self.editAssetId;
		self.getItemList();
	}
	self.isClickAddOnList = false;
	self.addOnListModalInstance.hide();
	}
});
EventBus.$on("close-add-on-data-modal", function (status) {
	if (typeof self != "undefined") {
	self.isClickAddOnList = status;
	self.addOnEditData = {};
	self.addOnListModalInstance.hide();
	}
});

/*##### PURCHASE ORDER #####*/
EventBus.$on("close-purchase-order", function (status,isList) {
	if (typeof self != "undefined") {
	self.isClickPurchaseOrder = status;
	// self.poEditData = {};
	self.purchaseOrderModalInstance.hide();
	if(isList) {
		self.getItemList();
	}
	}
});

EventBus.$on("purchase-item-list", function (poListData, type, indexList = null, tempDeleteIndex=null) {
	try {
	if (typeof self != "undefined") {
		let message = "";
		switch (type) {
		case "save":
			self.getItemList();
			message = self.staticMessages.vws_po_create_success;
			break;
		case "update":
			self.poEditData = {};
			self.getItemList();
			message = self.staticMessages.vws_po_update_success;
			break;
		case "tempSave":
			self.purchaseOrderList.push(poListData);
			self.poTempData = self.purchaseOrderList;
			message = self.staticMessages.vws_po_create_temp_success;
			self.addOnPoListCal();
			break;
		case "tempUpdate":
			self.purchaseOrderList.splice(poListData.current_index, 1, poListData);
			self.poTempData = self.purchaseOrderList;
			message = self.staticMessages.vws_po_update_success;
			self.addOnPoListCal();
			break;
		}
		self.isClickPurchaseOrder = false;
		self.purchaseOrderModalInstance.hide();
		self.showSAlert(
		message,
		"success",
		false,
		2
		);
	}
	} catch (error) {
	console.log('error>>>', error.line,error);
	}
});

EventBus.$on("close-vhc-cost-sheet", () => {
	if (typeof self != "undefined") {
	//self.isClickCostSheet = false;
	self.costSheetModalInstance.hide();
	}
});

EventBus.$on("flooring", function (calValue) {
	if (typeof self != "undefined") {
	if(calValue != null) {
		self.initFlooring = calValue.initFlooring;
		if(self.flooringType=="bodyCost") {
		self.bodyFlooringStartDate = calValue.startDate;
		self.bodyFlooringEndDate = calValue.endDate;
		self.bodyFlooringRate = calValue.rate;
		self.bodyFlooring = calValue.flooring;
		} else {
		self.chassisFlooring = calValue.flooring;
		self.chassisFlooringStartDate = calValue.startDate;
		self.chassisFlooringEndDate = calValue.endDate;
		self.chassisFlooringRate = calValue.rate;
		}
	}
	self.calculateFlooringModalInstance.hide();
	self.isClickCalFlooring = false;
	}
});

EventBus.$on("close-trade-detail", function (status) {
	if (typeof self != "undefined") {
	self.isClickAddTrade = status;
	self.tradeDetailModalInstance.hide();
	}
});

EventBus.$on("trade-details-data", function (data,type) {
	if (typeof self != "undefined") {
	self.isClickAddTrade = false;
	self.tradeDetailModalInstance.hide();
	switch(type) {
		case 'Save':
		self.isTradeSave = true;
		case 'Fresh':
		self.tradevalue = data.trade_in_value;
		self.tradepayoff = data.payoff;
		//Old Logic
		//self.overallowance = parseFloat(data.trade_in_value)-parseFloat(data.payoff);
		//New update on 27 Aug 2024
		self.overallowance = parseFloat(data.over_allowance);
		break;
		case 'Delete':
		self.tradevalue = 0;
		self.tradepayoff = 0;
		self.overallowance = 0;
		break;
	}
	self.tradeDetailsData = data;
	self.vhcCostCalculation();
	}
});

EventBus.$on("load-cost-sheet", (value) => {
	if (typeof self != "undefined"){
	self.costFromExcede = value;
	self.vhcCostCalculation('holdbackFixed', 'truckMarginAmount');
	//self.isClickCostSheet = false;
	self.costSheetModalInstance.hide();

	}
});

EventBus.$on("close-vehicle-detail", function (status) {
	if (typeof self != "undefined") {
	self.isClickVehicleDetail = status;
	$("#vehicleDetailModal").modal("hide");
	}
});

EventBus.$on("close-mail-modal", function (status) {
	if (typeof self != "undefined") {
	self.isClickMail = status;
	$("#mailModal").modal("hide");
	}
});
EventBus.$on("delete-addon-po-item", function (data,index,deleteType,parentIndex=null) {
	if (typeof self != "undefined") {
	self.deleteAddOnItem(data,index,deleteType,parentIndex);
	}
});
EventBus.$on("delete-po", function (id,purId) {
	if (typeof self != "undefined") {
	self.deletePO(id,purId);
	}
});
EventBus.$on("create-po-excede", function (poId) {
	if (typeof self != "undefined") {
	self.createPoExcede(poId);
	}
});


EventBus.$on("edit-addon-item", function (data,index) {
	if (typeof self != "undefined") {
	self.editAddOnItem(data,index);
	}
});
EventBus.$on("edit-po-item", function (data,index) {
	if (typeof self != "undefined") {
	self.editPoItem(data,index);
	}
});
</script>

<style
	lang="css"
	scoped
	src="../../../../public/static/css/vehicleWorksheetUpdate.css"
>
</style>

<style>
.vhc-select .dropdown-toggle {
	height: 32px !important;
	background: #eff5ff !important;
}
</style>
