<template>
  <div>
    <div class="vdc-filters-container">
      <div v-on:click="toggleFilterBlock()" class="vdc-filters-block">
        <span>{{ allLabelMessages.filters }}</span>
        <span
          class="badge badge-primary badge-pill bg-color-098de1"
          v-if="filterCount > 0"
          >{{ filterCount }} Filter<template v-if="filterCount > 1">s</template>
          applied</span
        >
        <button>
          <img src="/static/images/filter_icon.png" />
        </button>
      </div>
      <div v-show="isOpenFilterBlock" class="vdc-filters-area vtc-dealer-forms">
        <template v-for="(data, index) in parentHeaders">
          <div
            v-if="
              data.name != 'Action' &&
              data.filter_able != 'no' &&
              (typeof data.display == 'undefined' || data.display == true)
            "
            :key="index + 'field'"
            class="vdc-filters-area-block"
          >
            <label>{{ data.name == "Cus Id" ? "CusID" : data.name }}</label>
            <template v-if="data.type == 'date'">
              <div class="clearfix"></div>
              <div :class="'col-12 paddingleft0 ' + data.column_class">
                <VueCtkDateTimePicker
                  v-model="data.field"
                  range
                  :only-date="datePickerOptions.onlyDate"
                  :only-time="false"
                  :output-format="datePickerOptions.outputFormat"
                  :format="datePickerOptions.inputFormat"
                  :formatted="datePickerOptions.formatted"
                  :noClearButton="datePickerOptions.noClearButton"
                  :custom-shortcuts="datePickerOptions.customShortcuts"
                  :maxDate="datePickerOptions.maxDate"
                ></VueCtkDateTimePicker>
                <button
                  title="Reset"
                  v-on:click="
                    data.field = '';
                    resetFilters('date', data.field);
                  "
                  class="btn btn-primary clearDateFilter"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                  <!--<i class="fa fa-refresh" aria-hidden="true"></i>-->
                </button>
              </div>
            </template>
            <template v-else-if="data.type == 'drop_down'">
              <div class="col-12 paddingleft0">
                <v-select
                  :refreshFlag="refreshFlag"
                  v-model="data.field"
                  name="data.field"
                  placeholder="Type here"
                  label="text"
                  :options="Array.isArray(data.options) ? data.options : []"
                  class=""
                >
                </v-select>
              </div>
            </template>
            <template v-else-if="data.type == 'drop_down_multi_select'">
              <div class="col-12 paddingleft0">
                <v-select
                  :refreshFlag="refreshFlag"
                  v-model="data.field"
                  name="data.field"
                  placeholder="Select"
                  label="text"
                  :options="Array.isArray(data.options) ? data.options : []"
                  multiple
                >
                </v-select>
              </div>
            </template>
            <template v-else-if="data.type == 'search_select'">
              <div class="col-12 paddingleft0">
                <multiselect
                  v-model="data.field"
                  :options="searchDataArr"
                  :multiple="true"
                  :close-on-select="true"
                  :clear-on-select="false"
                  placeholder="Search & Select"
                  label="text"
                  track-by="text"
                  :preselect-first="true"
                  class="form-control multiselect_display_input mlt-sel-fltr"
                  @search-change="
                    (search, loading) => searchData(search, loading, data.modal)
                  "
                  @input="onOptionSelectDeselect(data.field)"
                >
                  <template slot="option" slot-scope="props">
                    <div
                      v-if="filteredData.includes(props.option.value)"
                      class="multiple_select_item selected_multiple_item"
                    >
                      <span>{{ props.option.text }}</span>
                    </div>
                    <div v-else class="multiple_select_item">
                      <span>{{ props.option.text }}</span>
                    </div>
                  </template>
                  <!-- Selection Custom Slot -->
                  <template
                    slot="selection"
                    slot-scope="{ values, search, isOpen }"
                  >
                    <span
                      class="multiselect__single"
                      v-if="values.length > 1 && !isOpen"
                      >{{ values.length }} selected</span
                    >
                    <span
                      class="multiselect__single"
                      v-else-if="values.length > 0 && values.length <= 1"
                      >{{ values[0].text }}</span
                    >
                    <span
                      class="multiselect__single pull-right"
                      v-on:click="clearSelectedFilter()"
                      ><i class="fa fa-close" aria-hidden="true"></i>
                    </span>
                  </template>
                  <template slot="noOptions">
                    <span>Search & Select</span>
                  </template>
                </multiselect>
              </div>
            </template>
            <template v-else-if="data.type == 'radio_button'">
              <div class="col-12 paddingleft0">
                <!-- <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.show_truck_online_label}}</div>
                                    </div> -->
                <div class="col-9 paddingleft0 custom-checkbox-v">
                  <label class="container">
                    <input
                      v-model="data.field"
                      value="true"
                      class="form-control"
                      type="checkbox"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </template>
            <template v-else>
              <input
                class="form-control"
                v-on:keyup.enter="applyFilter()"
                :placeholder="data.name == 'Cus Id' ? 'CusID' : data.name"
                v-model="data.field"
                :name="data.name"
                :id="data.field"
                type="text"
              />
            </template>
            <!--</template>-->
          </div>
        </template>
        <div class="clearfix"></div>
        <div class="">
          <button class="btn btn-primary" v-on:click="applyFilter()">
            Apply Filters
          </button>
          <button
            class="btn btn-primary"
            v-on:click="resetFilters('reset', '')"
          >
            Reset Filters
          </button>
          <button v-show="isResetSorting" class="btn btn-primary" v-on:click="resetSorting()">
            Reset Sorting
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import vSelect from "vue-select";
import Multiselect from "vue-multiselect";
export default {
  name: "FilterComponent",
  components: {
    datetime: Datetime,
    vSelect,
    Multiselect,
  },
  props: {
    parentHeaders: {
      type: Array,
      required: false,
      default: undefined,
    },
    filterCounts: 0,
    currentPageNameFilter: "",
    isResetSorting: {
      type: Boolean,
      default: true
    }
  },
  mixins: [commonMixins],
  data() {
    return {
      refreshFlag: true,
      filters: {},
      time_zone: "",
      selectedLocation: "",
      datePickerOptions: {
        range: true,
        onlyDate: true,
        outputFormat: "YYYY-MM-DD",
        inputFormat: "YYYY-MM-DD",
        formatted: process.env.VUE_APP_SYSTEM_DISPLAY_DATERANGE_FILTER_FORMAT, //'YYYY-MM-DD',
        noClearButton: true,
        maxDate: "",
        // Getting error when using the for date picker options
        // 'no-shortcuts': false,
        /*customShortcuts : [
                        { label: 'Yesterday', value: '-day', isSelected: false },
                        { label: 'This Week', value: 'week', isSelected: false },
                        { label: 'Last Week', value: '-week', isSelected: false },
                        { key: 'last7Days', label: 'Last 7 days', value: 7 },
                        { key: 'last30Days', label: 'Last 30 days', value: 30 },
                        { label: 'This Month', value: 'month', isSelected: false },
                        { label: 'Last Month', value: '-month', isSelected: false },
                        { key: 'thisYear', label: 'This year', value: 'year' },
                        { key: 'lastYear', label: 'Last year', value: '-year' }
                    ]*/
      },
      isOpenFilterBlock: false,
      filterCount: 0,
      searchDataArr: [],
      selectedData: null,
      filteredData: [],
    };
  },
  beforeMount() {
    self = this;
  },
  mounted: function () {
    self.staticInputLabelMessages = self.allLabelMessages;
    self.setDatePickerDateToCurrentDate();
    self.filterCount = self.filterCounts;
    if (
      self.filterCount ||
      self.currentPageNameFilter === localStorage.getItem("currentPageName")
    ) {
      self.isOpenFilterBlock = true;
    }
  },
  watch: {
    parentHeaders: function () {
      self.setDropDownValues();
    },
  },
  methods: {
    toggleFilterBlock: function () {
      $(window).scrollTop($(window).scrollTop() + 1);
      /* setTimeout(function () {
                    $(window).scrollTop($(window).scrollTop()-1);
                    $(".bottom_pagination").css({
                        'top' : ($(".page_title").outerHeight()  + $(".vdc-filters-container").outerHeight() + 55) + 'px'
                    });
                }, 100); */
      /* if($('.vdc-filters-area').is(':visible')) {
                    self.isOpenFilterBlock = false;
                } else {
                    self.isOpenFilterBlock = true;
                } */
      self.isOpenFilterBlock = !self.isOpenFilterBlock;
      /* self.$nextTick(function () {
                    var heightToListBody = (screen.availHeight - ($(".page_title").outerHeight() + $(".vdc-filters-container").outerHeight() + $(".vgt-wrap__footer").outerHeight() + $(".admin-footer").outerHeight()));
                    if(heightToListBody <= 500){
                        heightToListBody = 500;
                    }
                    $('.table-listing').css('max-height', (heightToListBody - 150) + 'px');
                }); */
    },
    applyFilter: function () {
      //console.log('applyFilter');
      let data = self.parentHeaders;
      self.$emit("applyFilterParentComponent", data);
    },
    resetSorting: function () {
      self.$emit("resetSorting");
    },
    resetFilters: function (field, value) {
      if (field == "date") {
      } else if (field == "drop_down") {
      } else if (field == "reset") {
        for (let i = 0; i < self.parentHeaders.length; i++) {
          self.parentHeaders[i]["field"] = "";
        }
      }
      self.applyFilter();
    },
    setDatePickerDateToCurrentDate: function () {
      var date = new Date();
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      self.today = today;
      self.datePickerDates = {
        start: today,
        end: today,
      };
      // self.datePickerOptions.maxDate = today;
    },
    setDropDownValues: function () {
      self.parentHeaders.forEach((header, index) => {
        const modalName = header["modal"];
        let optionsConditions = typeof header['options_conditions'] != 'undefined' ? header['options_conditions'] : {};
        if (modalName && modalName !== "modal") {
          self.loadAllData(
            [modalName],
            false,
            (callbackData) => self.loadDropdownDataCallback(callbackData, modalName, index),
            "undefined",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            optionsConditions
          );
        }
      });
    },
    loadDropdownDataCallback: function (callbackData, modalName, index) {
      self.parentHeaders[index]["options"] = callbackData[modalName];
      self.refreshFlag = !self.refreshFlag;
    },
    changeDatePickerDate: function () {
      self.applyFilter();
    },
    changedValue: function (value) {
      /* console.log('changedValue'); */
      self.applyFilter();
    },
    changedCategoryFor: function () {
      self.applyFilter();
    },
    searchData: function (search, loading, modalName) {
      if (search !== undefined && search.length > 2) {
        self.loadAllData(
          [modalName],
          true,
          function (callbackData) {
            self.loadDataCallback(callbackData, modalName);
          },
          loading,
          search
        );
      } else {
        self.searchDataArr = [];
      }
    },
    loadDataCallback: function (callbackData, modalName) {
      self.searchDataArr = callbackData[modalName];
    },
    onOptionSelectDeselect(selectedOptions) {
      self.filteredData = [];
      selectedOptions.map((data) => {
        self.filteredData.push(data.value);
      });
    },
    clearSelectedFilter() {
      self.filteredData = [];
      self.parentHeaders.map((data) => {
        if (data.type == "search_select") {
          data.field = [];
          return true;
        }
      });
    },
  },
};

EventBus.$on("is-open-filter-component", function (isOpenFlag) {
  if (typeof self != "undefined") {
    self.isOpenFilterBlock = isOpenFlag;
  }
});
</script>
<style scoped>
.resetButton {
  float: right;
  margin-top: -41px;
}

.mlt-sel-fltr > .multiselect__tags > .pull-right {
  right: 5px !important;
}
</style>

<style>
.vdc-filters-area .calender-left-0 .datepicker.flex.flex-direction-column {
  left: 0 !important;
}

.vdc-filters-area .calender-right-0 .datepicker.flex.flex-direction-column {
  right: 0 !important;
}

.vdc-filters-area .vdc-filters-area-block .mlt-sel-fltr .multiselect__tags {
  min-height: 33px !important;
}

.vdc-filters-area
  .vdc-filters-area-block
  .mlt-sel-fltr
  .multiselect__tags
  .multiselect__placeholder {
  color: #8f8f8f !important;
}

.vdc-filters-area
  .vdc-filters-area-block
  .mlt-sel-fltr
  .multiselect__option
  > span {
  padding-left: 10px;
  color: #8f8f8f;
}
</style>
